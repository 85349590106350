import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import type { MeterDataSource } from '@/types/meter-data-source/MeterDataSource.types';
import { DataPullStatus, DeviceStatus, type Note, UpdateFrequency } from '@/types/common.types';
import type { CustomerModel } from '@/types/customer/customer.types';

export interface MeterModel {
    id: string;
    object: string;
    account: string;
    meter_number: string;
    device_eui?: string;
    customer: CustomerModel;
    consent?: string;
    hosted_consent?: HostedConsentModel | null;
    description?: string;
    address?: string;
    last_data_pull_date?: string;
    last_data_pull_status?: DataPullStatus;
    metadata: Record<string, never>;
    updated_frequency: UpdateFrequency;
    data_source: MeterDataSource;
    data_source_info: Record<string, never>;
    status: DeviceStatus;
    notes?: Note[];
    tags?: string[];
    validity_state: {
        missing_consent: boolean;
        expired_consent: boolean;
        is_disabled_by_user: boolean;
        has_invalid_meter_number: boolean;
    };
    created_at: string;
    valid: boolean;
    enabled: boolean;
    error_code: MeterErrorCode | null;
}

export enum MeterErrorCode {
    NO_DATA_AVAILABLE = 'NO_DATA_AVAILABLE',
    INVALID_AUTHENTICATION_CREDENTIALS = 'INVALID_AUTHENTICATION_CREDENTIALS',
    NOT_REGISTERED_ON_DATASOURCE = 'NOT_REGISTERED_ON_DATASOURCE',
    // When the consent exists but the data on it is missing
    // this could indicate that the consent type is different from the one we are looking for
    // e.g using UK for NL
    MISSING_CONSENT_DATA = 'MISSING_CONSENT_DATA',
}

export enum MeterEnergyType {
    ELECTRICITY = 'electricity',
    GAS = 'gas',
}
