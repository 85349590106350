<template>
    <div class="background">
        <img :src="background" />
    </div>
    <div class="flex justify-center flex-col items-center w-full h-full z-10">
        <div
            class="bg-white border-gray-300 rounded-md shadow-lg p-20 flex justify-center flex-col items-center gap-8">
            <div class="max-w-xs md:max-w-sm">
                <img class="w-16" :src="logoUrl" alt="openvolt" />
            </div>
            <div class="flex flex-col gap-3 items-center">
                <h1 class="text-3xl">Welcome to Openvolt</h1>
                <h2>
                    Looks like you are not registered in our platform. Please contact us if you wish
                    to be part of the closed beta
                </h2>
            </div>
            <div class="flex gap-5">
                <button
                    class="inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md bg-black text-white"
                    @click="auth0Logout">
                    Sign out
                </button>
                <a
                    class="inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md bg-black text-white"
                    href="https://www.openvolt.com/contact"
                    target="_blank">
                    Contact us
                </a>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import logoUrl from '@/assets/images/openvolt-icon.svg';
import { useUserStore } from '@/stores/user';
import background from '@/assets/images/background-gradient.svg';

const userStore = useUserStore();
const { logout } = useAuth0();

function auth0Logout() {
    logout({ logoutParams: { returnTo: window.location.origin } });
    userStore.handleLogout();
}
</script>

<style>
.background-gradient {
    background: linear-gradient(241deg, #982ff7 20%, #524add 56%, #6668eb 84%);
}

.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 0;
}
</style>
