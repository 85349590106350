<script setup lang="ts">
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';

enum ConsentStatus {
    VALID = 'valid',
    PENDING = 'pending',
    EXPIRED = 'expired',
    REVOKED = 'revoked',
}

const props = defineProps<{
    hostedConsent: HostedConsentModel;
}>();

function status() {
    if (props.hostedConsent.valid) {
        return ConsentStatus.VALID;
    }
    if (
        new Date(props.hostedConsent.expiry) > new Date() &&
        new Date(props.hostedConsent.link_expiration_date) > new Date() &&
        !props.hostedConsent.valid &&
        !props.hostedConsent.revoked_at
    ) {
        return ConsentStatus.PENDING;
    }

    if (
        new Date(props.hostedConsent.expiry) < new Date() ||
        new Date(props.hostedConsent.link_expiration_date) < new Date() ||
        props.hostedConsent.revoked_at
    ) {
        if (props.hostedConsent.revoked_at) {
            return ConsentStatus.REVOKED;
        }
        return ConsentStatus.EXPIRED;
    }

    return '';
}
</script>

<template>
    <span
        v-if="status() === ConsentStatus.VALID"
        class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-green-50 text-green-700 ring-green-600/20"
        >Valid</span
    >
    <span
        v-if="status() === ConsentStatus.PENDING"
        class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-yellow-50 text-yellow-700 ring-yellow-600/20"
        >Pending</span
    >
    <span
        v-if="status() === ConsentStatus.EXPIRED"
        class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/20"
        >Expired</span
    >
    <span
        v-if="status() === ConsentStatus.REVOKED"
        class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-orange-50 text-orange-700 ring-orange-600/20"
        >Revoked</span
    >
</template>

<style scoped></style>
