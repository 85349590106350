<script setup lang="ts">
import { ConsentFlowType } from '@/types/hosted-consents/hosted-consent.types';

const props = defineProps<{
    value: ConsentFlowType | null;
}>();
const emit = defineEmits(['change']);

function onSelectFlowType(event: any) {
    emit('change', event.target.value === 'null' ? null : event.target.value);
}
</script>

<template>
    <select
        @change="onSelectFlowType($event)"
        class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        <option value="null">-- Select a consent flow --</option>
        <option
            :selected="flowType === props.value"
            v-for="flowType in ConsentFlowType"
            :key="flowType"
            :value="flowType">
            {{ flowType }}
        </option>
    </select>
</template>
