<script setup lang="ts">
import Required from '@/components/Required/Required.vue';

const props = defineProps<{
    name: string;
    type: string;
    description: string;
    required?: boolean;
}>();
</script>

<template>
    <div>
        <div class="flex">
            <span class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                >{{ props.name }} <Required v-if="required"
            /></span>
            <span class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5">{{
                props.type
            }}</span>
        </div>

        <div class="text-sm font-sm text-gray-600">{{ props.description }}</div>
    </div>
</template>
