<script setup lang="ts">
const props = defineProps<{
    disabled: boolean;
}>();

const emit = defineEmits(['click']);

function handleClick() {
    emit('click');
}
</script>

<template>
    <button
        @click="handleClick"
        :class="[
            'rounded-full text-xs font-xs px-3 py-0.5',
            props.disabled
                ? 'text-gray-400 bg-gray-100 border border-gray-200'
                : 'bg-sky-200 border border-sky-300',
        ]">
        <slot></slot>
    </button>
</template>

<style scoped></style>
