<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useHomepageStore } from '@/stores/homepageStore';
import StatCard from '@/components/StatCard/StatCard.vue';
import Heading from '@/components/Heading/Heading.vue';
const homepageStore = useHomepageStore();
import { onClickOutside } from '@vueuse/core';
import { BoltIcon, UsersIcon, HandThumbUpIcon } from '@heroicons/vue/24/outline';
import MetersOverview from '@/components/MetersOverview/MetersOverview.vue';
import Button from '@/components/Button/Button.vue';

const error = ref(false)
const target = ref(null);

onClickOutside(target, clearCurrentSelectedDay);
function clearCurrentSelectedDay() {
    if (homepageStore.currentSelectedRequest !== null) {
        homepageStore.currentSelectedRequest = null;
    } else {
        if (homepageStore.currentSelectedDay !== null) {
            homepageStore.currentSelectedDay = null;
        }
    }
    homepageStore.currentSelectedEvent = null;
}

onMounted(async () => {
    await homepageStore.getAllMeters();
});

watch(
    () => homepageStore.currentSelectedEvent,
    () => {
        if (homepageStore.currentSelectedEvent) {
            homepageStore.currentSelectedRequest = null;
            homepageStore.currentSelectedDay = null;
        }
    },
);

onMounted(async () => {
    await homepageStore.getStats();
    if (homepageStore.stats === null) {
        error.value = true;
    }
})
</script>

<template>
    <div v-if="error">
        <h1>Error!</h1>
    </div>
    <div v-if="!error && homepageStore.stats !== null" class="flex flex-col gap-8">
        <div class="grid grid-cols-3 gap-4">
            <StatCard>
                <template v-slot:icon
                    ><BoltIcon class="w-12 text-sky-600 bg-sky-100 p-2 rounded"
                /></template>
                <template v-slot:stat>{{ homepageStore.stats?.meters }}</template>
                <template v-slot:default>Meters</template>
                <template v-slot:link>
                    <RouterLink to="/meters">View All</RouterLink>
                </template>
            </StatCard>
            <StatCard>
                <template v-slot:icon
                    ><UsersIcon class="w-12 text-sky-600 bg-sky-100 p-2 rounded"
                /></template>
                <template v-slot:stat>{{ homepageStore.stats?.customers }}</template>
                <template v-slot:default>Customers</template>
                <template v-slot:link>
                    <RouterLink to="/customers">View All</RouterLink>
                </template>
            </StatCard>
            <StatCard>
                <template v-slot:icon
                    ><HandThumbUpIcon class="w-12 text-sky-600 bg-sky-100 p-2 rounded"
                /></template>
                <template v-slot:stat>{{ homepageStore.stats?.hostedConsents }}</template>
                <template v-slot:default>Hosted Consents</template>
                <template v-slot:link>
                    <RouterLink to="/hosted-consents">View All</RouterLink>
                </template>
            </StatCard>
        </div>
        <hr />
        <div
            v-if="homepageStore.allMeters.length === 0"
            class="flex h-full justify-center items-center h-32">
            <div class="flex gap-4 flex-col items-center">
                <div class="text-xl text-gray-500">You currently have no meters to show</div>
                <RouterLink to="/meters/new"><Button text="Create a meter" /></RouterLink>
            </div>
        </div>
        <div v-else class="flex flex-col gap-4">
            <Heading>Meters Overview</Heading>
            <MetersOverview />
        </div>
    </div>
</template>
