<script setup lang="ts">
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue'
import Button from '@/components/Button/Button.vue'
import ConsentFlowTypeSelect from '@/components/ConsentFlowTypeSelect/ConsentFlowTypeSelect.vue'
import ConsentMethodSelect from '@/components/ConsentMethodSelect/ConsentMethodSelect.vue'
import CustomerSelect from '@/components/CustomerSelect/CustomerSelect.vue'
import DateInput from '@/components/DateInput/DateInput.vue'
import FormLabel from '@/components/FormLabel/FormLabel.vue'
import { useHostedConsentStore } from '@/stores/hostedConsentStore'
import { ConsentMethod, type ConsentFlowType } from '@/types/hosted-consents/hosted-consent.types'
import { ref } from 'vue'
import { useToast } from 'vue-toastification'

const hostedConsentStore = useHostedConsentStore();
const toast = useToast()
const defaultExpiry = new Date()
defaultExpiry.setFullYear(new Date().getFullYear() + 1)

const customer = ref('')
const description = ref('')
const expiry = ref<string>(defaultExpiry.toISOString().slice(0, 10))
const flowType = ref<ConsentFlowType | null>(null)
const consentMethod = ref<ConsentMethod | null>(null)
const redirectUrl = ref('')
const consenteeEmail = ref('')
const consenteeFullName = ref('')
const consenteeJobTitle = ref('')
const countrySpecificConfiguration = ref<Record<string, unknown>>()
const requiresCountryData = ref<boolean>()

function handleSetCustomer (customerId: string) {
    customer.value = customerId
}

function handleSetExpiry (date: string) {
    expiry.value = date
}

function handleSetFlowType (type: ConsentFlowType | null) {
    flowType.value = type
}

function handleSetConsentMethod (method: ConsentMethod) {
    consentMethod.value = method
}

function handleSetCountrySpecificConfiguration (event: Event) {
    console.log('triggered', flowType.value)
    const target = event.target as HTMLSelectElement
    if (flowType.value?.startsWith("fr.")) {
        countrySpecificConfiguration.value = {
            data_usage: target.value,
            country: 'FR'
        }
    }
}

async function handleCreateHostedConsent () {
    if (!customer.value) {
        toast.error('Please select a customer')
        return
    }

    if (!redirectUrl.value) {
        toast.error('Please enter a redirect url')
        return
    } else if (!redirectUrl.value.includes('://')) {
        toast.error('Please include the protocol in the redirect url')
        return
    }

    // URL validation throws an error if the URL is invalid
    // ugly but it works
    try {
        new URL(redirectUrl.value)
    } catch (error) {
        toast.error('Please enter a valid redirect url')
        return
    }

    if (!flowType.value) {
        toast.error('Please select a flow type')
        return
    }

    // This needs to be this way because there's no way to set
    // an initial value to a component on mount and pass it on to the ref
    // otherwise I would need to replicate all the logic to validate if
    // country configurations are needed here as well
    if (Boolean(requiresCountryData.value) === true && !countrySpecificConfiguration.value?.country) {
        toast.error('This flow type requires extra configuration')
        return
    }

    await hostedConsentStore.createHostedConsent({
        customer_id: customer.value.length > 0 ? customer.value : undefined,
        description: description.value,
        expiry: expiry.value,
        redirect_url: redirectUrl.value,
        flow_type: flowType.value,
        ...({ country_specific_configuration: countrySpecificConfiguration.value ?? undefined }),
        consentee_email: consenteeEmail.value.length > 0 ? consenteeEmail.value : undefined,
        consentee_full_name:
            consenteeFullName.value.length > 0 ? consenteeFullName.value : undefined,
        consentee_job_title:
            consenteeJobTitle.value.length > 0 ? consenteeJobTitle.value : undefined,
        consent_method:
            consentMethod.value && consentMethod.value.length > 0 ? consentMethod.value : undefined,
    });
}
</script>

<template>
    <ApiPageHeader title="Create a new hosted consent" endpoint="/hosted-consents" method="post" />
    <div class="mt-4 pt-4 border-t border-gray-300"></div>
    <div class="space-y-2 border border-gray-200 sm:space-y-2 sm:divide-y sm:divide-gray-200 bg-gray-100 rounded-lg p-2 mb-5">
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel required name="customer_id" description="The id of the customer for this consent" type="string" />
            <CustomerSelect @change="handleSetCustomer" v-bind:value="customer" />
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel required name="expiry" description="The expiry date for this consent" type="string" />
            <DateInput v-bind:value="expiry" @change="handleSetExpiry" />
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel required name="redirect_url" description="The url to redirect to after consent is given/rejected" type="string" />
            <input v-model="redirectUrl"
                   class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                   type="text" />
            <div class="pl-4 text-sm flex items-center"></div>
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel required name="flow_type" description="The type of consent flow the user will complete" type="string" />
            <ConsentFlowTypeSelect @change="handleSetFlowType" v-bind:value="flowType" />
        </div>
        <div v-if="flowType?.startsWith('fr.')" class="grid grid-cols-3 py-2 items-center">
            <FormLabel name="data_usage" description="Specify how you're going to use the data on this consent." type="string" required />
            <input type="hidden" ref="requiresCountryData" :value="true" />
            <select name="data_usage" @change="handleSetCountrySpecificConfiguration"
                    class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option value="">-- Select an option --</option>
                <option value="energy-management">Energy Management</option>
                <option value="real-estate">Real State Decarbonization</option>
                <option value="demand-response">Demand Response and VPP</option>
            </select>
            <div class="pl-4 text-sm flex items-center"></div>
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel name="description" description="Give your hosted consent a description to help identify it." type="string" />
            <input placeholder="Enter a description" v-model="description"
                   class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                   type="text" />
            <div class="pl-4 text-sm flex items-center"></div>
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel name="consentee_email" description="The email of the consenting party can be pre-filled" type="string" />
            <input v-model="consenteeEmail"
                   class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                   type="text" />
            <div class="pl-4 text-sm flex items-center"></div>
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel name="consentee_full_name" description="The full name of the consenting party can be pre-filled" type="string" />
            <input v-model="consenteeFullName"
                   class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                   type="text" />
            <div class="pl-4 text-sm flex items-center"></div>
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel name="consentee_job_title" description="The job title of the consenting party can be pre-filled" type="string" />
            <input v-model="consenteeJobTitle"
                   class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                   type="text" />
            <div class="pl-4 text-sm flex items-center"></div>
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel name="consent_method" description="You can auto-select the method of consent for the consenting party if you wish"
                       type="string" />
            <ConsentMethodSelect @change="handleSetConsentMethod" v-bind:value="consentMethod" />
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel name="meter_numbers" description="You can prime a consent with meter numbers" type="string" />
            <span class="text-gray-400">Coming Soon!</span>
        </div>
    </div>
    <Button text="Create" @click="handleCreateHostedConsent" />
</template>

<style scoped></style>
