<script setup lang="ts">
import FormLabel from '@/components/FormLabel/FormLabel.vue';
import { onMounted, ref } from 'vue';
import {
    type EmailSubscriptionResponse,
    SubscriptionType,
    type WebhookSubscriptionResponse,
} from '@/types/subscriptions/subscription.types';
import Button from '@/components/Button/Button.vue';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import type { EventResponse } from '@/types/events/event.types';
import { useSubscriptionStore } from '@/stores/subscriptionStore';
import { updateRef } from '@/helpers/refHelper';
import { useRoute, useRouter } from 'vue-router';

const userStore = useUserStore();
const subscriptionStore = useSubscriptionStore();
const api = new ApiClient();
const route = useRoute();
const router = useRouter();

const description = ref<string | null>('');
const subscriptionType = ref<SubscriptionType>(SubscriptionType.EMAIL);
const emailAddress = ref('');
const subject = ref<string | null>('');
const url = ref('');
const eventOptions = ref<EventResponse[]>([]);
const events = ref<string[]>([]);
const id = ref<string | null>(null);

onMounted(async () => {
    await updateRef(api.listEvents(userStore.currentApiKey), eventOptions);
    await loadSubscription();
});

async function loadSubscription() {
    if (route.params.id) {
        id.value = route.params.id as string;
        subscriptionType.value = route.params.type as SubscriptionType;
        const subscription = await subscriptionStore.getSubscription(
            id.value,
            subscriptionType.value,
        );
        if (subscription) {
            description.value = subscription.description || null;
            events.value = subscription.events;
            if (subscriptionType.value === SubscriptionType.EMAIL) {
                emailAddress.value = (subscription as EmailSubscriptionResponse).email;
                subject.value = (subscription as EmailSubscriptionResponse).subject || null;
            } else {
                url.value = (subscription as WebhookSubscriptionResponse).url;
            }
        }
    }
}

function buildSubscription() {
    const data = {
        description: description.value,
        events: events.value,
    };
    if (subscriptionType.value === SubscriptionType.EMAIL) {
        return { ...data, email: emailAddress.value, subject: subject.value };
    } else {
        return { ...data, url: url.value };
    }
}

async function handleCreateSubscription() {
    const subscription = await subscriptionStore.createSubscription(
        buildSubscription(),
        subscriptionType.value,
    );
    if (subscription) {
        await router.push(`/event-subscriptions/${subscriptionType.value}/${subscription.id}`);
    }
}

async function handleUpdateSubscription() {
    if (id.value) {
        const subscription = await subscriptionStore.updateSubscription(
            id.value,
            buildSubscription(),
            subscriptionType.value,
        );
        if (subscription) {
            await router.push(`/event-subscriptions/${subscriptionType.value}/${subscription.id}`);
        }
    }
}

function disableSubmit() {
    const invalidEmail = subscriptionType.value === SubscriptionType.EMAIL && !emailAddress.value;
    const invalidWebhook = subscriptionType.value === SubscriptionType.WEBHOOK && !url.value;
    return !subscriptionType.value || invalidEmail || invalidWebhook || events.value.length === 0;
}

function handleSelectEvent(event: string) {
    const foundIndex = events.value.findIndex(e => e === event);
    if (foundIndex > -1) {
        events.value = [
            ...events.value.slice(0, foundIndex),
            ...events.value.slice(foundIndex + 1),
        ];
    } else {
        events.value = [...events.value, event];
    }
}
</script>

<template>
    <div class="mb-8">
        <RouterLink to="/developer-dashboard?tab=events" class="text-sky-700 hover:text-sky-600"
            >Back to Events</RouterLink
        >
    </div>
    <div class="mb-1 flex justify-between items-start">
        <div>
            <h1 class="text-2xl font-semibold">Create new Event Subscription</h1>
        </div>
    </div>
    <div class="mt-4 pt-4 border-t border-gray-300"></div>
    <div
        class="space-y-2 border border-gray-200 sm:space-y-2 sm:divide-y sm:divide-gray-200 bg-gray-100 rounded-lg p-2 mb-5">
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel
                required
                name="Subscription Type"
                description="The type of subscription"
                type="string" />
            <select
                v-model="subscriptionType"
                class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m">
                <option :value="SubscriptionType.EMAIL">Email</option>
                <option :value="SubscriptionType.WEBHOOK">Webhook</option>
            </select>
        </div>
        <div
            v-if="subscriptionType === SubscriptionType.EMAIL"
            class="grid grid-cols-3 py-2 items-center">
            <FormLabel
                required
                name="Email"
                description="The email address where the event notifications should be sent"
                type="string" />
            <input
                v-model="emailAddress"
                class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                type="text" />
        </div>
        <div
            v-if="subscriptionType === SubscriptionType.EMAIL"
            class="grid grid-cols-3 py-2 items-center">
            <FormLabel
                name="Subject"
                description="The email subject for the event notification"
                type="string" />
            <input
                v-model="subject"
                class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                type="text" />
        </div>
        <div
            v-if="subscriptionType === SubscriptionType.WEBHOOK"
            class="grid grid-cols-3 py-2 items-center">
            <FormLabel
                required
                name="URL"
                description="The URL that should be invoked when the event occurs"
                type="string" />
            <input
                v-model="url"
                class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                type="text" />
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel
                name="Description"
                description="The description of the event subscription"
                type="string" />
            <input
                v-model="description"
                class="disabled:text-gray-300 disabled:border-gray-200 mt-1 px-3 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m"
                type="text" />
            <div class="pl-4 text-sm flex items-center"></div>
        </div>
        <div class="grid grid-cols-3 py-2 items-center">
            <FormLabel
                required
                name="Events"
                description="The events to subscribe to"
                type="string" />
            <div class="grid grid-cols-1 gap-2 col-span-2">
                <div
                    @click="() => handleSelectEvent(event.id)"
                    v-for="event in eventOptions"
                    class="flex gap-2 items-center hover:cursor-pointer">
                    <input
                        type="checkbox"
                        :value="event.id"
                        :checked="!!events.find(e => e === event.id)" />
                    <label>{{ event.id }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="flex border-0 justify-end">
        <Button
            v-if="id === null"
            text="Create"
            @click="handleCreateSubscription"
            :disabled="disableSubmit()"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" />
        <Button
            v-if="id !== null"
            text="Update"
            @click="handleUpdateSubscription"
            :disabled="disableSubmit()"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" />
    </div>
</template>
