import type { ApiRequest } from '@/types/apiRequests';
import type {
    CreateCustomerRequest,
    Customer,
    DeleteCustomerRequest,
    GetCustomerDetailRequest,
    GetCustomersRequest,
    UpdateCustomerRequest,
} from '@/types/customer';
import type {
    CreateInverterRequest,
    DeleteInverterRequest,
    DisableInverterRequest,
    EnableInverterRequest,
    GetInverterDetailRequest,
    Inverter,
    UpdateInverterRequest,
} from '@/types/inverter';
import type {
    CreateMeterRequest,
    DeleteMeterRequest,
    DisableMeterRequest,
    EnableMeterRequest,
    GetMeterDetailRequest,
    GetMetersRequest,
    Meter,
    UpdateMeterRequest,
} from '@/types/meter';
import type { CreateUserRequest, RemoveUserRequest, RotateApiKeyRequest } from '@/types/user';

import axios, { AxiosError, type AxiosResponse, type Method } from 'axios';

import createUrlWithParams, { type ParamsMap } from './createUrlWithParams';
import removeEmptyProperties from './removeEmptyProperties';

import type { ToplineStats } from '@/stores/homepageStore';
import type { CreateConsentPayload } from '@/stores/hostedConsentStore';
import type {
    CreateNotificationAccountRequest,
    NotificationAccountResponse,
} from '@/types/account/notification-account.types';
import type { ImageMetadata } from '@/types/common.types';
import type { EventResponse, TriggerEventResponse } from '@/types/events/event.types';
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import type { GetIntervalRequest } from '@/types/interval';
import type { PageQuery, PageResponse } from '@/types/page/page.types';
import type {
    CreateEmailSubscriptionRequest,
    CreateWebhookSubscriptionRequest,
    EmailSubscriptionResponse,
    UpdateEmailSubscriptionRequest,
    UpdateWebhookSubscriptionRequest,
    WebhookSubscriptionResponse,
} from '@/types/subscriptions/subscription.types';

export type APIResponse = { isSuccessful: true } | { isSuccessful: false; errorMessage: string };

export function normalizeErrorMessage(errorObject: AxiosError<any>) {
    return errorObject.response!.data.errors[0].message;
}

function errorResponse(errorObject: AxiosError<unknown>) {
    return { isSuccessful: false, errorMessage: normalizeErrorMessage(errorObject) };
}

export class ApiClient {
    private baseURL = import.meta.env.VITE_JWT_AUDIENCE + '/v1';
    private axiosClient = axios.create({
        baseURL: this.baseURL,
    });

    private async request(method: Method, url: string, apiKey: string, body?: object) {
        try {
            const result = await this.axiosClient.request({
                method,
                url,
                data: body,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            return result.data;
        } catch (e) {
            if (typeof e === 'string') throw Error(e);
            else throw e;
        }
    }

    async getUserData(email: string, accessToken: string) {
        const encodedEmail = encodeURIComponent(email);
        try {
            const user = (
                await this.axiosClient.get(`/users?email=${encodedEmail}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
            ).data.data[0];
            return {
                email: user.email,
                id: user._id,
                accounts: user.accounts,
                accessToken: '',
            };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getUserById(id: string, accessToken: string) {
        try {
            const { data: result } = await this.axiosClient.get(`/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const user = result.data;
            const accounts = user.accounts.map((account: any) => {
                return {
                    id: account._id,
                    name: account.name,
                };
            });
            return {
                email: user.email,
                id: user._id,
                accounts: accounts,
            };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getAccountUsers(accountId: string, accessToken: string) {
        try {
            const { data: result } = await this.axiosClient.get(`/accounts/${accountId}/users`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const users = result.data.map((user: any) => {
                return {
                    email: user.email,
                    id: user._id,
                };
            });
            return users;
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getAccountApiKeys(accountId: string, accessToken: string) {
        try {
            const { data: result } = await this.axiosClient.get(`/accounts/${accountId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return { testApiKey: result.data.test_api_key, liveApiKey: result.data.live_api_key };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async updateAccountTheme(id: string, theme: string, accessToken: string) {
        const result = await this.axiosClient.put(
            `/accounts/${id}`,
            {
                theme,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return result.data;
    }

    async updateAccountLogo(
        id: string,
        logo: string,
        metadata: ImageMetadata,
        accessToken: string,
    ) {
        const result = await this.axiosClient.put(
            `/accounts/${id}`,
            {
                logo,
                logo_metadata: metadata,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return result.data;
    }

    async rotateAccountApiKey(requestObject: RotateApiKeyRequest, accessToken: string) {
        const { rotateLiveKey, id } = requestObject;
        const result = await this.axiosClient.put(
            `/accounts/${id}/rotate-api-key`,
            {
                rotateLiveKey: rotateLiveKey,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return result.data;
    }

    async createNewUser(requestObject: CreateUserRequest, accessToken: string) {
        try {
            const result = await this.axiosClient.post('/users', requestObject, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return result.data;
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async removeUserFromAccount(requestObject: RemoveUserRequest, accessToken: string) {
        try {
            const { id, accounts } = requestObject;
            const result = await this.axiosClient.put(
                `/users/${id}`,
                { accounts: accounts },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );
            return result.data;
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getCustomers({
        apiKey,
        ...queryParams
    }: GetCustomersRequest): Promise<{ customers: Customer[]; hasMore: boolean }> {
        try {
            const paramsMap = {
                currentPage: 'page',
                pageSize: 'page_size',
                email: 'email',
                customerId: 'customer_id',
            };

            let url: string = '/customers?';

            url = createUrlWithParams(url, paramsMap, queryParams);

            const result = await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            const customers = result.data.data.map((customer: any) => {
                return {
                    id: customer._id,
                    name: customer.name,
                    email: customer.email,
                    accountId: customer.account._id,
                    logoUrl: customer.logo_url,
                    notes: customer.notes,
                    address: customer.address,
                };
            });
            return { customers, hasMore: result.data.has_more };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getCustomer({ id, apiKey }: GetCustomerDetailRequest): Promise<Customer> {
        try {
            const result = await this.axiosClient.get(`/customers/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            return {
                id: result.data._id,
                name: result.data.name,
                email: result.data.email,
                accountId: result.data.account._id,
                logoUrl: result.data.logo_url,
                notes: result.data.notes,
                address: result.data.address,
            };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async createNewCustomer({ apiKey, ...params }: CreateCustomerRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.post(
                '/customers',
                { ...params },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async deleteCustomer({ apiKey, id }: DeleteCustomerRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.delete(`/customers/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async updateCustomer({ apiKey, id, ...params }: UpdateCustomerRequest): Promise<APIResponse> {
        try {
            const filteredParams = removeEmptyProperties(params);
            const result = await this.axiosClient.put(
                `/customers/${id}`,
                { ...filteredParams },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getMeters(
        requestObject: GetMetersRequest,
    ): Promise<{ meters: Meter[]; totalCount: number }> {
        try {
            const paramsMap: ParamsMap = {
                currentPage: 'page',
                pageSize: 'page_size',
                meterNumber: 'meter_number',
                meterId: 'meter_id',
                customerId: 'customer_id',
                customerName: 'customer_name',
                sortBy: 'sort_by',
                sortOrder: 'sort_order',
                hostedConsent: 'hosted_consent',
                enabled: 'enabled',
                errorCode: 'error_code',
                status: 'status',
            };

            const { apiKey, ...queryParams } = requestObject;
            let url: string = '/meters?';

            url = createUrlWithParams(url, paramsMap, queryParams);

            const result = await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            const meters: Meter[] = result.data.data.map((meter: any) => {
                return {
                    id: meter._id,
                    meterNumber: meter.meter_number,
                    dataSource: meter.data_source,
                    customer: meter.customer,
                    address: meter.address,
                    description: meter.description,
                    status: meter.status,
                    consentId: meter.consent,
                    hostedConsent: meter.hosted_consent,
                    lastDataPullDate: meter.last_data_pull_date,
                    lastDataPullStatus: meter.last_data_pull_status,
                    latestDateEntry: meter.latest_date_entry,
                    enabled: meter.enabled,
                    errorCode: meter.error_code,
                    valid: meter.valid,
                    created_at: meter.created_at,
                    meter_type: meter.meter_type,
                    one_time_data_pull: meter.one_time_data_pull,
                };
            });

            return { meters, totalCount: result.data.total_count };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getInverters(requestObject: GetMetersRequest) {
        try {
            const paramsMap = {
                currentPage: 'page',
                pageSize: 'page_size',
                inverterNumber: 'inverter_number',
                inverterId: 'inverter_id',
                customerId: 'customer_id',
                customerName: 'customer_name',
                sortBy: 'sort_by',
                sortOrder: 'sort_order',
                consentId: 'consent_id',
                missingConsent: 'missing_consent',
                expiredConsent: 'expired_consent',
                isDisabledByUser: 'is_disabled_by_user',
                hasInvalidInverterNumber: 'has_invalid_inverter_number',
            };

            const { apiKey, ...queryParams } = requestObject;
            let url: string = '/inverters?';

            url = createUrlWithParams(url, paramsMap, queryParams);

            const result = await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            const inverters: Inverter[] = result.data.data.map((inverter: any) => {
                return {
                    id: inverter._id,
                    inverterNumber: inverter.inverter_number,
                    dataSource: inverter.data_source,
                    customerName: inverter.customer?.name,
                    address: inverter.address,
                    description: inverter.description,
                    status: inverter.status,
                    consentId: inverter.consent,
                    lastDataPullDate: inverter.last_data_pull_date,
                    lastDataPullStatus: inverter.last_data_pull_status,
                    validityState: {
                        missingConsent: inverter.validity_state?.missing_consent,
                        expiredConsent: inverter.validity_state?.expired_consent,
                        isDisabledByUser: inverter.validity_state?.is_disabled_by_user,
                        hasInvalidMeterNumber: inverter.validity_state?.has_invalid_inverter_number,
                    },
                };
            });

            return { inverters, totalCount: result.data.total_count };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getMeter(requestObject: GetMeterDetailRequest): Promise<Meter> {
        try {
            const { meterId, apiKey } = requestObject;
            const result = await this.axiosClient.get(`/meters/${meterId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            const {
                _id,
                meter_number,
                data_source,
                customer,
                address,
                description,
                status,
                last_data_pull_date,
                last_data_pull_status,
                consent,
                hosted_consent,
                validity_state,
                meter_type,
                one_time_data_pull,
                latest_date_entry,
                enabled,
                error_code,
                valid,
                created_at,
            } = result.data.data;
            return {
                id: _id,
                meterNumber: meter_number,
                dataSource: data_source,
                customer: customer,
                consentId: consent,
                hostedConsent: hosted_consent,
                address,
                description,
                status,
                lastDataPullDate: last_data_pull_date,
                lastDataPullStatus: last_data_pull_status,
                latestDateEntry: latest_date_entry,
                enabled,
                errorCode: error_code,
                valid,
                created_at,
                validityState: {
                    missingConsent: validity_state?.missing_consent,
                    expiredConsent: validity_state?.expired_consent,
                    isDisabledByUser: validity_state?.is_disabled_by_user,
                    hasInvalidMeterNumber: validity_state?.has_invalid_meter_number,
                },
                meter_type,
                one_time_data_pull,
            };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getInverter(requestObject: GetInverterDetailRequest) {
        try {
            const { inverterId, apiKey } = requestObject;
            const result = await this.axiosClient.get(`/inverters/${inverterId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            const {
                _id,
                inverter_number,
                data_source,
                customer,
                address,
                description,
                status,
                last_data_pull_date,
                last_data_pull_status,
                consent,
                hosted_consent,
                validity_state,
            } = result.data;
            return {
                id: _id,
                inverterNumber: inverter_number,
                dataSource: data_source,
                customerName: customer?.name,
                consentId: consent,
                hostedConsent: hosted_consent,
                address,
                description,
                status,
                lastDataPullDate: last_data_pull_date,
                lastDataPullStatus: last_data_pull_status,
                validityState: {
                    missingConsent: validity_state?.missing_consent,
                    expiredConsent: validity_state?.expired_consent,
                    isDisabledByUser: validity_state?.is_disabled_by_user,
                    hasInvalidInverterNumber: validity_state?.has_invalid_meter_number,
                },
            };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async createNewMeter({ apiKey, ...params }: CreateMeterRequest): Promise<APIResponse> {
        try {
            const filteredParams = removeEmptyProperties(params);
            const result = await this.axiosClient.post(
                '/meters',
                { ...filteredParams },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async createNewInverter({ apiKey, ...params }: CreateInverterRequest): Promise<APIResponse> {
        try {
            const filteredParams = removeEmptyProperties(params);
            const result = await this.axiosClient.post(
                '/inverters',
                { ...filteredParams },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async deleteMeter({ apiKey, id }: DeleteMeterRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.delete(`/meters/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            return { isSuccessful: result.status === 204 } as APIResponse;
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async deleteInverter({ apiKey, id }: DeleteInverterRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.delete(`/inverters/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async updateMeter({ apiKey, id, ...params }: UpdateMeterRequest): Promise<APIResponse> {
        try {
            const filteredParams = removeEmptyProperties(params);
            const result = await this.axiosClient.put(
                `/meters/${id}`,
                { ...filteredParams },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async updateInverter({ apiKey, id, ...params }: UpdateInverterRequest): Promise<APIResponse> {
        try {
            const filteredParams = removeEmptyProperties(params);
            const result = await this.axiosClient.put(
                `/inverters/${id}`,
                { ...filteredParams },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async enableMeter({ apiKey, id }: EnableMeterRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.post(
                `/meters/${id}/enable`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async enableInverter({ apiKey, id }: EnableInverterRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.post(
                `/inverters/${id}/enable`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async disableMeter({ apiKey, id }: DisableMeterRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.post(
                `/meters/${id}/disable`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async disableInverter({ apiKey, id }: DisableInverterRequest): Promise<APIResponse> {
        try {
            const result = await this.axiosClient.post(
                `/inverters/${id}/disable`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
            return { isSuccessful: result.data.success };
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getIntervalData(requestObject: GetIntervalRequest, asCSV: true): Promise<string>;
    async getIntervalData(
        requestObject: GetIntervalRequest,
        asCSV: false,
    ): Promise<{ consumption: any[]; intensity: any[] }>;
    async getIntervalData(
        requestObject: GetIntervalRequest,
        asCSV: boolean,
    ): Promise<string | { consumption: any[]; intensity: any[] }> {
        try {
            const paramsMap = {
                meterNumber: 'meter_number',
                inverterNumber: 'inverter_number',
                customerId: 'customer_id',
                meterId: 'meter_id',
                inverterId: 'inverter_id',
                granularity: 'granularity',
                lookback: 'lookback',
                startDate: 'start_date',
                endDate: 'end_date',
                includeCarbonEmissions: 'include_carbon_emissions',
            };

            const { apiKey, ...queryParams } = requestObject;

            let url: string = '/interval-data?';
            url = createUrlWithParams(url, paramsMap, queryParams);

            const result = await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                    ...(asCSV ? { Accept: 'text/csv' } : {}),
                },
            });

            // When CSV is selected the return will be a string
            if (asCSV) {
                return result.data;
            }

            const returnData: {
                consumption: any[];
                intensity: any[];
            } = {
                consumption: [],
                intensity: [],
            };

            if (queryParams.meterId) {
                returnData.consumption = result.data.data.map((interval: any) => {
                    return {
                        x: interval.start_interval,
                        y: interval.consumption,
                        type: result.data.energyType,
                    };
                });
            } else if (queryParams.inverterId) {
                returnData.consumption = result.data.data.map((interval: any) => {
                    return {
                        x: interval.start_interval,
                        y0: interval.system_consumption,
                        y1: interval.pv_generation,
                        y2: interval.battery_charge,
                        y3: interval.battery_discharge,
                    };
                });
            }
            returnData.intensity = result.data.data.map((interval: any) => {
                return {
                    x: interval.start_interval,
                    y: interval.carbon_emission,
                };
            });
            return returnData;
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getHostedConsents(apiKey: string) {
        try {
            const url: string = '/hosted-consents';

            const result = await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
            return result.data;
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async createHostedConsent(
        apiKey: string,
        payload: CreateConsentPayload,
    ): Promise<AxiosResponse<{ success: boolean; data: HostedConsentModel }>> {
        try {
            const url: string = '/hosted-consents';

            return await this.axiosClient.post<{ success: boolean; data: HostedConsentModel }>(
                url,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getHostedConsent(apiKey: string, id: string) {
        try {
            const url: string = `/hosted-consents/${id}`;

            return await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getHostedConsentEvidence(apiKey: string, id: string) {
        try {
            const url: string = `/hosted-consents/${id}/evidences`;

            return await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async getHostedConsentMeters(apiKey: string, id: string) {
        try {
            const url: string = `/hosted-consents/${id}/meters`;

            return await this.axiosClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async deleteHostedConsent(apiKey: string, id: string) {
        try {
            const url: string = `/hosted-consents/${id}`;

            return await this.axiosClient.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async meterNumberLookup(apiKey: string, meter_number: string, data_source: string) {
        try {
            const url: string = `/meters/meter-number-lookup`;

            return await this.axiosClient.get(url, {
                params: {
                    meter_number,
                    data_source,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
            });
        } catch (e: any) {
            throw errorResponse(e);
        }
    }

    async listWebhookSubscriptions(apiKey: string): Promise<WebhookSubscriptionResponse[]> {
        return this.request('GET', '/webhook-subscriptions', apiKey);
    }

    async getWebhookSubscription(apiKey: string, id: string): Promise<WebhookSubscriptionResponse> {
        return this.request('GET', `/webhook-subscriptions/${id}`, apiKey);
    }

    async createWebhookSubscription(
        apiKey: string,
        subscription: CreateWebhookSubscriptionRequest,
    ): Promise<WebhookSubscriptionResponse> {
        return this.request('POST', '/webhook-subscriptions', apiKey, subscription);
    }

    async updateWebhookSubscription(
        apiKey: string,
        id: string,
        subscription: UpdateWebhookSubscriptionRequest,
    ): Promise<WebhookSubscriptionResponse> {
        return this.request('PUT', `/webhook-subscriptions/${id}`, apiKey, subscription);
    }

    async deleteWebhookSubscription(apiKey: string, id: string): Promise<void> {
        return this.request('DELETE', `/webhook-subscriptions/${id}`, apiKey);
    }

    async listEmailSubscriptions(apiKey: string): Promise<EmailSubscriptionResponse[]> {
        return this.request('GET', '/email-subscriptions', apiKey);
    }

    async getEmailSubscription(apiKey: string, id: string): Promise<EmailSubscriptionResponse> {
        return this.request('GET', `/email-subscriptions/${id}`, apiKey);
    }

    async createEmailSubscription(
        apiKey: string,
        subscription: CreateEmailSubscriptionRequest,
    ): Promise<EmailSubscriptionResponse> {
        return this.request('POST', '/email-subscriptions', apiKey, subscription);
    }

    async updateEmailSubscription(
        apiKey: string,
        id: string,
        subscription: UpdateEmailSubscriptionRequest,
    ): Promise<EmailSubscriptionResponse> {
        return this.request('PUT', `/email-subscriptions/${id}`, apiKey, subscription);
    }

    async deleteEmailSubscription(apiKey: string, id: string): Promise<void> {
        return this.request('DELETE', `/email-subscriptions/${id}`, apiKey);
    }

    async getNotificationAccount(apiKey: string): Promise<NotificationAccountResponse> {
        return this.request('GET', '/notification-accounts', apiKey);
    }

    async createNotificationAccount(
        apiKey: string,
        notificationAccount: CreateNotificationAccountRequest,
    ): Promise<NotificationAccountResponse> {
        return this.request('POST', '/notification-accounts', apiKey, notificationAccount);
    }

    async listEvents(apiKey: string): Promise<EventResponse[]> {
        return this.request('GET', '/events', apiKey);
    }

    async listAccountEvents(
        apiKey: string,
        pageQuery?: PageQuery,
    ): Promise<PageResponse<TriggerEventResponse>> {
        pageQuery = { page: 1, page_size: 10, ...(pageQuery || {}) };
        return this.request(
            'GET',
            `/notification-accounts/events?page=${pageQuery.page}&page_size=${pageQuery.page_size}`,
            apiKey,
        );
    }

    async getAccountEvent(apiKey: string, eventId: string): Promise<TriggerEventResponse> {
        return this.request('GET', `/notification-accounts/events/${eventId}`, apiKey);
    }

    async getStats(accountId: string, accessToken: string): Promise<null | ToplineStats> {
        return await this.axiosClient
            .get(`/accounts/${accountId}/stats`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res => {
                if (res.data.success) {
                    return res.data.data;
                }
                return null;
            });
    }

    async getApiRequests(
        accountId: string,
        accessToken: string,
    ): Promise<null | Array<{ date: string; requests: ApiRequest[] }>> {
        return await this.axiosClient
            .get(`/accounts/${accountId}/api-requests`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res => {
                if (res.data.success) {
                    return res.data.data;
                }
                return null;
            });
    }

    async getApiRequest(
        accountId: string,
        id: string,
        accessToken: string,
    ): Promise<null | ApiRequest> {
        return await this.axiosClient
            .get(`/accounts/${accountId}/api-requests/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res => {
                if (res.data.success) {
                    return res.data.data;
                }
                return null;
            });
    }
}
