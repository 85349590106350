<script setup lang="ts">
import type { EventResponse, TriggerEventResponse } from '@/types/events/event.types';
import { useRouter } from 'vue-router';
import type { PageQuery, PageResponse } from '@/types/page/page.types';
import { useNotificationAccountStore } from '@/stores/notificationAccountStore';
import { computed, onMounted, ref } from 'vue';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import GenericChip from '@/components/Chips/GenericChip/GenericChip.vue';
import { useHomepageStore } from '@/stores/homepageStore';
import Loading from '@/components/Loading/Loading.vue';
import RecentEventRow from '@/components/RecentEvents/RecentEventRow/RecentEventRow.vue';

const api = new ApiClient();
const userStore = useUserStore();
const router = useRouter();

const loading = ref(true);
const eventOptions = ref<EventResponse[]>([]);
const events = ref<PageResponse<TriggerEventResponse> | null>(null);
const selectedEventGroups = ref<Set<string>>(new Set());
const homepageStore = useHomepageStore();
const notificationAccountStore = useNotificationAccountStore();

onMounted(async () => {
    await navigate({ page: 1, page_size: 500 });
    const listedEvents = await api.listEvents(userStore.currentApiKey);
    eventOptions.value = listedEvents;

    selectedEventGroups.value = new Set(listedEvents.map(e => e.id.split('.')[0]));
});

async function navigate(page?: PageQuery) {
    events.value = await notificationAccountStore.listAccountEvents(page);
    loading.value = false;
}

function toggleEventOption(id: string) {
    const foundIndex = [...selectedEventGroups.value].findIndex(o => o === id);
    if (foundIndex > -1) {
        selectedEventGroups.value = new Set([
            ...[...selectedEventGroups.value].slice(0, foundIndex),
            ...[...selectedEventGroups.value].slice(foundIndex + 1),
        ]);
    } else {
        selectedEventGroups.value = new Set([...selectedEventGroups.value, id]);
    }
}

function handleSelectEvent(event: TriggerEventResponse) {
    homepageStore.currentSelectedEvent = event;
}

const filteredEvents = computed(() => {
    const filtered = events.value?.results.filter(e => {
        const group = e.event_id.split('.')[0];
        return [...selectedEventGroups.value].findIndex(eg => eg === group) > -1;
    });
    return filtered ?? [];
});
</script>

<template>
    <div class="flex flex-col gap-4 items-stretch">
        <div class="flex gap-4 flex-wrap">
            <GenericChip
                v-for="group in new Set(events?.results.map(e => e.event_id.split('.')[0]))"
                :disabled="[...selectedEventGroups].findIndex(o => o === group) === -1"
                @click="() => toggleEventOption(group)"
                >{{ group }}</GenericChip
            >
        </div>
        <div>
            <div class="events-list border border-gray-300 bg-white h-[600px]">
                <div v-if="filteredEvents.length === 0" class="p-4 h-full">
                    <div v-if="loading" class="flex items-center justify-center h-full">
                        <Loading :isOpen="loading" />
                    </div>
                    <div v-else class="flex items-center justify-center h-full">
                        <span class="text-2xl text-gray-400">No events to show.</span>
                    </div>
                </div>
                <RecentEventRow
                    :event="event"
                    :class="[
                        'px-4 py-1.5 hover:cursor-pointer border-b border-gray-200',
                        homepageStore.currentSelectedEvent?.id === event.id
                            ? 'bg-sky-100'
                            : 'hover:bg-slate-100',
                    ]"
                    v-for="event in filteredEvents"
                    :key="event.id"
                    @click="() => handleSelectEvent(event)" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.events-list {
    overflow-y: auto;
}
</style>
