<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSubscriptionStore } from '@/stores/subscriptionStore';
import {
    type EmailSubscriptionResponse,
    SubscriptionType,
    type WebhookSubscriptionResponse,
} from '@/types/subscriptions/subscription.types';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton/index.vue';
import DeliveryAttemptsTable from '@/components/DeliveryAttemptsTable/DeliveryAttemptsTable.vue';
import Button from '@/components/Button/Button.vue';
import ConfirmAction from '@/components/ConfirmAction/index.vue';

const route = useRoute();
const router = useRouter();
const subscriptionStore = useSubscriptionStore();
const subscriptionType = ref<SubscriptionType>(SubscriptionType.EMAIL);
const subscription = ref<EmailSubscriptionResponse | WebhookSubscriptionResponse | null>(null);
const confirmModalOpen = ref<boolean>(false);

onMounted(async () => {
    subscriptionType.value = route.params.type as SubscriptionType;
    const id = route.params.id as string;
    await subscriptionStore.getSubscription(id, subscriptionType.value);
});

watch(
    () => subscriptionStore.subscription,
    () => (subscription.value = subscriptionStore.subscription),
);

async function deleteSubscription() {
    if (subscription.value) {
        await subscriptionStore.deleteSubscription(subscription.value.id, subscriptionType.value);
        await router.push('/event-subscriptions');
    }
}
</script>
<template>
    <div :key="subscription?.id" v-if="subscription !== null">
        <div class="mb-8">
            <RouterLink to="/developer-dashboard?tab=events" class="text-sky-700 hover:text-sky-600"
                >Back to Events</RouterLink
            >
        </div>
        <ApiPageHeader
            :title="`${
                subscriptionType == SubscriptionType.EMAIL ? 'Email' : 'Webhook'
            } Subscription`"
            :endpoint="`/${
                subscriptionType == SubscriptionType.EMAIL ? 'email' : 'webhook'
            }-subscriptions/${subscription?.id}`"
            method="get" />
        <div class="mt-4 pt-4 border-t border-gray-300"></div>
        <div v-if="subscription" class="divide-y divide-gray-100 border-t border-b border-gray-100">
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Subscription Id</span>
                    <span class="text-gray-400">The id of this subscription</span>
                </div>
                <span>{{ subscription.id }}</span>
                <div>
                    <CopyToClipboardButton :toCopy="subscription.id" name="Subscription Id" />
                </div>
            </div>
            <div
                v-if="subscriptionType == SubscriptionType.EMAIL"
                class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Email Address</span>
                    <span class="text-gray-400">The email address to send notifications to</span>
                </div>
                <span>{{ (subscription as EmailSubscriptionResponse).email }}</span>
                <div>
                    <CopyToClipboardButton
                        :toCopy="(subscription as EmailSubscriptionResponse).email"
                        name="Email Address" />
                </div>
            </div>
            <div
                v-if="subscriptionType == SubscriptionType.EMAIL"
                class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Subject</span>
                    <span class="text-gray-400">The email subject for the event notification</span>
                </div>
                <span>{{ (subscription as EmailSubscriptionResponse).subject }}</span>
                <div v-if="(subscription as EmailSubscriptionResponse).subject">
                    <CopyToClipboardButton
                        :toCopy="(subscription as EmailSubscriptionResponse).subject!"
                        name="Email Address" />
                </div>
            </div>
            <div
                v-if="subscriptionType == SubscriptionType.WEBHOOK"
                class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Webhook URL</span>
                    <span class="text-gray-400">The URL to send notifications to</span>
                </div>
                <span>{{ (subscription as WebhookSubscriptionResponse).url }}</span>
                <div>
                    <CopyToClipboardButton
                        :toCopy="(subscription as WebhookSubscriptionResponse).url"
                        name="Webhook URL" />
                </div>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Description</span>
                    <span class="text-gray-400">The description of this subscription</span>
                </div>
                <span>{{ subscription.description }}</span>
            </div>
            <div class="py-2 grid grid-cols-3 items-start gap-2">
                <div class="flex flex-col">
                    <span>Events</span>
                    <span class="text-gray-400"
                        >The events that this subscription is subscribed to</span
                    >
                </div>
                <div>
                    <span
                        v-for="event in subscription.events"
                        :key="event"
                        class="inline-flex items-center rounded-md px-2 m-1 py-1 text-xs font-medium ring-1 ring-inset bg-blue-50 text-blue-700 ring-blue-600/20"
                        >{{ event }}</span
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 flex justify-end space-x-1">
        <Button
            text="Edit"
            @click="
                router.push(`/event-subscriptions/${subscriptionType}/${subscription?.id}/edit`)
            " />
        <Button text="Delete" delete @click="confirmModalOpen = true" />
        <ConfirmAction
            v-if="confirmModalOpen"
            title="Delete Event Subscription"
            message="Do you want to delete this Event Subscription?"
            confirmButtonText="Delete "
            @confirm="deleteSubscription"
            @close="confirmModalOpen = false">
        </ConfirmAction>
    </div>
    <h2 class="text-xl font-large text-gray-500 mt-4 pt-4 border-t border-gray-300">
        Delivery Attempts
    </h2>
    <delivery-attempts-table></delivery-attempts-table>
</template>
