<script setup lang="ts">
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useHostedConsentStore } from '@/stores/hostedConsentStore';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import HostedConsentsTable from '@/components/HostedConsentsTable/HostedConsentsTable.vue';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';

const apiClient = new ApiClient();
const hostedConsentStore = useHostedConsentStore();
const userStore = useUserStore();
const router = useRouter();

const validConsents = ref<HostedConsentModel[]>([]);
const pendingConsents = ref<HostedConsentModel[]>([]);
const expiredConsents = ref<HostedConsentModel[]>([]);

onMounted(async () => {
    const data = await apiClient.getHostedConsents(userStore.currentApiKey).catch(() => null);
    if (data) {
        hostedConsentStore.hostedConsents = data;
    }
});

watch(
    () => userStore.currentApiKey,
    async () => {
        const data = await apiClient.getHostedConsents(userStore.currentApiKey).catch(() => null);
        if (data) {
            hostedConsentStore.hostedConsents = data;
        }
    },
);

watch(
    () => hostedConsentStore.hostedConsents,
    async () => {
        validConsents.value = hostedConsentStore.hostedConsents.filter(hc => hc.valid);
        pendingConsents.value = hostedConsentStore.hostedConsents.filter(
            hc =>
                !hc.valid &&
                new Date(hc.expiry) > new Date() &&
                new Date(hc.link_expiration_date) > new Date() &&
                !hc.consented_at,
        );
        expiredConsents.value = hostedConsentStore.hostedConsents.filter(hc => {
            const today = new Date();
            return (
                !hc.consented_at &&
                (new Date(hc.expiry) < today ||
                    hc.revoked_at ||
                    new Date(hc.link_expiration_date) < today)
            );
        });
    },
);
</script>

<template>
    <ApiPageHeader
        title="Hosted Consents"
        endpoint="/hosted-consents"
        method="get"
        button-link="/hosted-consents/new"
        button-link-text="Create hosted consent" />
    <h2 class="text-xl font-large text-gray-500 mt-4 pt-4 border-t border-gray-300">
        Active Consents
    </h2>
    <HostedConsentsTable :hosted-consents="validConsents" />
    <div class="mb-10">
        <hr />
    </div>
    <h2 class="text-xl font-large text-gray-500">Pending Consents</h2>
    <HostedConsentsTable :hosted-consents="pendingConsents" />
    <div class="mb-10">
        <hr />
    </div>
    <h2 class="text-xl font-large text-gray-500">Expired/Revoked Consents</h2>
    <HostedConsentsTable :hosted-consents="expiredConsents" />
</template>

<style scoped></style>
