import { useUserStore } from '@/stores/user';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import type NavigationItem from '@/types/NavigationItem';
import { FeatureFlag } from '@/types/FeatureFlag';
import {
    BoltIcon,
    BuildingOfficeIcon,
    CalendarDaysIcon,
    HandThumbUpIcon,
    SignalIcon,
    SunIcon,
    UsersIcon,
    WrenchScrewdriverIcon,
} from '@heroicons/vue/24/outline';
import { HomeIcon } from '@heroicons/vue/24/outline';

export function useNavigation() {
    const navigationItems = ref<NavigationItem[]>([
        {
            name: 'Home',
            href: '/',
            icon: HomeIcon,
            isActive: false,
        },
        {
            name: 'Developer Dashboard',
            href: '/developer-dashboard',
            icon: WrenchScrewdriverIcon,
            isActive: false,
        },
        {
            name: 'Customers',
            href: '/customers',
            icon: UsersIcon,
            isActive: false,
        },

        {
            name: 'Meters',
            href: '/meters',
            icon: BoltIcon,
            isActive: false,
        },

        {
            name: 'Hosted Consents',
            href: '/hosted-consents',
            icon: HandThumbUpIcon,
            isActive: false,
        },
    ]);

    const userStore = useUserStore();
    const route = useRoute();

    if (userStore.featureFlagList.includes(FeatureFlag.InverterBeta)) {
        //We would like to add it as the third item
        navigationItems.value.splice(2, 0, {
            name: 'Inverters',
            href: '/inverters',
            icon: SunIcon,
            isActive: false,
        });
    }

    function setActive(name: string) {
        navigationItems.value = navigationItems.value.map(navItem => {
            if (navItem.name === name) {
                navItem.isActive = true;
            } else {
                navItem.isActive = false;
            }
            return navItem;
        });
    }

    navigationItems.value.forEach(navItem => {
        if (route.path.includes(navItem.href)) {
            setActive(navItem.name);
        }
    });

    return { navigationItems, setActive };
}
