<template>
    <div class="w-full overflow-x-hidden">
        <ApiPageHeader
            title="List all meters"
            endpoint="/meters"
            method="GET"
            button-link="/meters/new"
            button-link-text="Add Meter" />
        <Disclosure
            v-slot="{ open }"
            v-if="userStore.featureFlagList.includes(FeatureFlag.ElectralinkMeterLookup)">
            <DisclosureButton
                class="mt-4 py-4 border-t border-b border-gray-200 w-full flex bg-gray-50 gap-4 px-4 items-center">
                <h2 class="text-sm text-gray-900">Electralink Meter Lookup</h2>
                <ChevronDownIcon v-if="!open" class="w-5" />
                <ChevronUpIcon v-if="open" class="w-5" />
            </DisclosureButton>
            <DisclosurePanel>
                <div class="mt-4">
                    <ElectralinkMeterLookup />
                </div>
            </DisclosurePanel>
        </Disclosure>
        <Disclosure v-slot="{ open }">
            <DisclosureButton
                class="mt-4 py-4 border-t border-b border-gray-200 w-full flex bg-gray-50 gap-4 px-4 items-center">
                <h2 class="text-sm text-gray-900">QUERY PARAMS</h2>
                <ChevronDownIcon v-if="!open" class="w-5" />
                <ChevronUpIcon v-if="open" class="w-5" />
            </DisclosureButton>
            <DisclosurePanel class="text-gray-500">
                <form class="mt-4">
                    <div
                        class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >page</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >integer</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    The page number to retrieve
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                        v-model="currentPage" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >page_size</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >integer</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    The maximum number of meters to retrieve per page
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                        v-model="pageSize" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >meter_number</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >integer</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    filter by meter_number
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                        v-model="meterNumber" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >meter_id</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >integer</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">Filter by meter_id</div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                        v-model="meterId" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >customer_id</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >integer</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    Filter by customer_id
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                        v-model="customer" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >customer_name</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >string</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    Filter by customer_name
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                        v-model="customerName" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <!-- <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >hosted_consent</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >string</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    Filter by hosted_consent
                                </div>
                            </div> -->

                            <!-- <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                        v-model="hostedConsent" />
                                </div>
                            </div> -->
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >sort_by</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >string</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">Sort results by</div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div class="flex">
                                    <div>
                                        <select
                                            v-model="sortBy"
                                            class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                            <option>last_data_pull_date</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >sort_order</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >string</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    Sort ascending or descending
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div class="flex">
                                    <div>
                                        <select
                                            v-model="sortOrder"
                                            class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                            <option>asc</option>
                                            <option>desc</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >error_code</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >string</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    Filter for meters with a specific error code
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div class="flex">
                                    <div>
                                        <select
                                            v-model="errorCode"
                                            class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                            <option></option>
                                            <option>{{ MeterErrorCode.NO_DATA_AVAILABLE }}</option>
                                            <option>
                                                {{ MeterErrorCode.NOT_REGISTERED_ON_DATASOURCE }}
                                            </option>
                                            <option>
                                                {{
                                                    MeterErrorCode.INVALID_AUTHENTICATION_CREDENTIALS
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >status</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >string</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    Query for active/inactive meters
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div class="flex">
                                    <div>
                                        <select
                                            v-model="status"
                                            class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                            <option></option>
                                            <option>active</option>
                                            <option>inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                            <div>
                                <div class="flex">
                                    <span
                                        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                        >enabled</span
                                    ><span
                                        class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                        >boolean</span
                                    >
                                </div>

                                <div class="text-sm font-sm text-gray-600">
                                    Whether the meter as been enabled or disabled
                                </div>
                            </div>

                            <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div class="flex">
                                    <div>
                                        <select
                                            v-model="enabled"
                                            class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                            <option></option>
                                            <option>true</option>
                                            <option>false</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex border-0 justify-end">
                        <button
                            type="button"
                            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            @click="onSubmit">
                            Submit
                        </button>
                    </div>
                </form>
            </DisclosurePanel>
        </Disclosure>
        <div class="mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">Meters</h1>
                    <p class="mt-2 text-sm text-gray-700">
                        A list of all your meters filtered by the above query params
                    </p>
                </div>
            </div>
            <table class="divide-y w-full divide-gray-300">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Meter
                        </th>
                        <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Customer
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Details
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Data source
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Data freshness
                        </th>
                        <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Last pull date 
                    </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Enabled
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Valid
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Consent
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Error Code
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-if="meters.length === 0">
                        <td colspan="3" class="py-8 text-center text-gray-500">
                            You don't have any meters added, yet.
                        </td>
                    </tr>
                    <tr v-for="meter in meters" :key="meter.meterNumber">
                        <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                            <div class="flex items-center">
                                <div>
                                    <BoltIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <div
                                    class="ml-4 hover:cursor-pointer"
                                    @click="showDetails(meter.id)">
                                    <div class="font-medium text-gray-900">
                                        {{ meter.meterNumber }}
                                    </div>
                                    <div class="mt-1 text-gray-500">
                                        <span v-if="meter.meter_type">{{ meter.meter_type }}</span>
                                        <span v-else>{{ meter.dataSource }}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <div class="text-gray-900">{{ meter.customer.name }}</div>
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-5 text-sm text-gray-500 w-1/6 overflow-auto">
                            <div class="text-gray-900">{{ meter.description }}</div>
                            <div class="mt-1 text-gray-500">
                                {{ meter.address }}
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-5 text-xs text-gray-500">
                            <div class="mt-1 text-gray-900">
                                {{ formatDataSource(meter.dataSource) }}
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-5 text-xs text-gray-900">
                            <div class="text-gray-900">
                                {{ formatDate(meter.latestDateEntry) }}
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-5 text-xs text-gray-900">
                            <div class="text-gray-900">
                                {{
                                    getTimeDiff(
                                        dateDiffInMins(
                                            new Date(meter.lastDataPullDate),
                                            new Date(),
                                        ),
                                    )
                                }}
                            </div>
                        </td>

                        <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <div class="text-gray-900">
                                <span
                                    class="items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                    :class="{
                                        'bg-green-50 text-green-700 ring-green-600/20':
                                            meter.enabled === true,
                                        'bg-red-50 text-red-700 ring-red-600/20':
                                            meter.enabled === false,
                                    }"
                                    >{{ meter.enabled }}</span
                                >
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <div class="text-gray-900">
                                <span
                                    class="items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                    :class="{
                                        'bg-green-50 text-green-700 ring-green-600/20':
                                            meter.valid === true,
                                        'bg-red-50 text-red-700 ring-red-600/20':
                                            meter.valid === false,
                                    }"
                                    >{{ meter.valid }}</span
                                >
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <div class="text-gray-900">
                                <span
                                    class="items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                    :class="{
                                        'bg-green-50 text-green-700 ring-green-600/20':
                                            meter.hostedConsent != null &&
                                            meter.hostedConsent.valid,
                                        'bg-orange-50 text-orange-700 ring-orange-600/20':
                                            meter.hostedConsent != null &&
                                            !meter.hostedConsent.valid,
                                        'bg-red-50 text-red-700 ring-red-600/20':
                                            meter.hostedConsent == null,
                                    }">
                                    <!-- Display check mark if meter.hostedConsent is not null -->
                                    <template
                                        v-if="
                                            meter.hostedConsent != null && meter.hostedConsent.valid
                                        ">
                                        <i class="icon">&#x2713;</i>
                                        <!-- Unicode for check mark -->
                                    </template>

                                    <!-- Display X if meter.hostedConsent is null -->
                                    <template v-else>
                                        <i class="icon">&#x2717;</i>
                                        <!-- Unicode for X -->
                                    </template>
                                    <span v-if="meter.hostedConsent != null">
                                        <span v-if="meter.hostedConsent.valid"> valid </span>
                                        <span v-else> invalid </span>
                                    </span>
                                    <span v-else> missing</span>
                                </span>
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <div class="text-gray-900">
                                <span v-if="meter.errorCode"
                                    class="items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                    >{{ meter.errorCode }}</span
                                >
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { BoltIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
import { dateDiffInMins } from '@/utils/dateDiffInMins';
import type { Meter } from '@/types/meter';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import type { GetMetersRequest } from '@/types/meter';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { FeatureFlag } from '@/types/FeatureFlag';
import ElectralinkMeterLookup from '@/components/ElectralinkMeterLookup/ElectralinkMeterLookup.vue';
import moment from 'moment';
import { formatDataSource } from '@/utils/formatDataSource';

const apiClient = new ApiClient();
const userStore = useUserStore();
const router = useRouter();

const currentPage = ref<number>(1);
const pageSize = ref<number>(300);
const meterNumber = ref<string>('');
const meterId = ref<string>('');
const customer = ref<string>('');
const customerName = ref<string>('');
const sortBy = ref<string>('last_data_pull_date');
const sortOrder = ref<string>('desc');

const enabled = ref<boolean | null>(null);
const valid = ref<boolean>(false);
const errorCode = ref<string | null>(null);
const status = ref<string | null>(null);

const meters = ref<Meter[]>([]);
const resultsNumber = ref<number>(0);

function getTimeDiff(time_diff: number) {
    //Let's round down the timing
    // If it's less than 60 mins, then we use the minute value
    // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
    // if it's more than 1440 mins then we use the lowest day

    if (isNaN(time_diff)) {
        return 'None';
    }
    let returnLastSeen = '';
    if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
    } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
    } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
    }

    return returnLastSeen;
}

function showDetails(meterId: string) {
    router.push('/meters/' + meterId);
}

enum MeterErrorCode {
    NO_DATA_AVAILABLE = 'NO_DATA_AVAILABLE',
    INVALID_AUTHENTICATION_CREDENTIALS = 'INVALID_AUTHENTICATION_CREDENTIALS',
    NOT_REGISTERED_ON_DATASOURCE = 'NOT_REGISTERED_ON_DATASOURCE',
}

async function getMeters(): Promise<{ meters: Meter[]; totalCount: number }> {
    const requestObject: GetMetersRequest = {
        currentPage: currentPage.value,
        pageSize: pageSize.value,
        meterNumber: meterNumber.value,
        meterId: meterId.value,
        customerId: customer.value,
        // commented until api works
        // sortBy: sortBy.value,
        // sortOrder: sortOrder.value,
        apiKey: userStore.currentApiKey,
        enabled: enabled.value ?? undefined,
        valid: valid.value ?? undefined,
        errorCode: errorCode.value ?? undefined,
        status: status.value ?? undefined,
    };

    return await apiClient.getMeters(requestObject).catch(() => {
        return { meters: [], totalCount: 0 }
    })
}

function formatDate(value: string) {
    if (value) {
        return moment(String(value)).format('DD/MM/YY HH:mm')
    }
    return 'None';
}

async function handleMetersFetch() {
    const metersData = await getMeters();
    meters.value = metersData.meters;
    resultsNumber.value = metersData.totalCount;
}

async function onSubmit() {
    handleMetersFetch();
}

onMounted(async () => {
    if (router.currentRoute.value.query?.customer_id) {
        customer.value = router.currentRoute.value.query.customer_id as string;
    }
    handleMetersFetch();
});

watch(
    () => userStore.currentApiKey,
    async () => {
        await handleMetersFetch();
    },
);
</script>
