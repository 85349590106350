import './assets/main.css';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import { createAuth0 } from '@auth0/auth0-vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueApexCharts from 'vue3-apexcharts';
import hljsVuePlugin from '@highlightjs/vue-plugin';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/atom-one-light.css';

const app = createApp(App);

Sentry.init({
    app,
    dsn: 'https://0fb3c7f5529c778db75261c4f661ad55@o4506908744351744.ingest.us.sentry.io/4506955123916800',
    environment: import.meta.env.VITE_APP_ENV || 'dev',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const pinia = createPinia();

pinia.use(piniaPluginPersistedState);

app.use(pinia);
app.use(router);
app.use(
    createAuth0({
        domain: import.meta.env.VITE_AUTH_DOMAIN || '',
        clientId: import.meta.env.VITE_CLIENT_ID || '',
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: import.meta.env.VITE_JWT_AUDIENCE || '',
        },
    }),
);
app.use(Toast);
app.use(VueApexCharts);

hljs.registerLanguage('json', json);
app.use(hljsVuePlugin);
app.mount('#app');
