<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { type ActivityDay, useHomepageStore } from '@/stores/homepageStore';
import Heading from '@/components/Heading/Heading.vue';
import type { ApiRequest } from '@/types/apiRequests';
import Loading from '@/components/Loading/Loading.vue';

const props = defineProps<{
    errorsOnly: boolean;
}>();

const homepageStore = useHomepageStore();

const error = ref(false);
const loading = ref(true);
function handleSetApiRequestDay(e: MouseEvent, day: ActivityDay) {
    e.preventDefault();
    homepageStore.currentSelectedDay = day;
    homepageStore.currentSelectedRequest = null;
}

const gridData = computed(() => {
    const apiRequests = homepageStore?.apiRequests ?? [];
    return valueCalculations(apiRequests)?.gridData ?? [];
});

const highestRequestsCount = computed(() => {
    const apiRequests = homepageStore?.apiRequests ?? [];
    return valueCalculations(apiRequests)?.highestRequestsCount ?? 0;
});

onMounted(async () => {
    await homepageStore.getApiRequests().catch(() => {
        error.value = true;
    });
    if (homepageStore.currentSelectedDay === null) {
        const lastDay = gridData.value[gridData.value.length - 1];
        homepageStore.currentSelectedDay = lastDay;
    }
    loading.value = false;
});

function valueCalculations(
    apiRequests: Array<{
        date: string;
        requests: ApiRequest[];
    }> | null,
) {
    if (apiRequests === null) {
        error.value = true;
    } else {
        let highestRequests = 0;
        const data: any = [];

        apiRequests.forEach(({ date, requests }, index: number) => {
            const day = new Date(date).getDay();
            const month = new Date(date).getMonth();
            const successRequests = apiRequests[index].requests.filter(
                r => !r.status_code || r.status_code < 400,
            ) as ApiRequest[];
            const failedRequests = apiRequests[index].requests.filter(
                r => r.status_code >= 400,
            ) as ApiRequest[];
            data.push({
                date,
                day,
                month,
                requests: {
                    success: successRequests,
                    failed: failedRequests,
                },
            });
            if (props.errorsOnly && failedRequests.length > highestRequests) {
                highestRequests = failedRequests.length;
            }
            if (!props.errorsOnly && successRequests.length > highestRequests) {
                highestRequests = successRequests.length;
            }
        });

        return {
            gridData: data,
            highestRequestsCount: highestRequests,
        };
    }
}
</script>

<template>
    <div v-if="loading" class="w-full flex justify-center">
        <Loading />
    </div>
    <div v-if="error">
        <div
            class="h-52 flex items-center justify-center text-gray-400 text-lg border border-gray-300">
            There was an error trying to load your API activity.
        </div>
    </div>
    <div v-if="!loading && !error && homepageStore.apiRequests !== null">
        <div class="activity-grid">
            <div class="squares">
                <div
                    :class="[
                        new Date(day.date).getDay() === 6 ? 'pr-4' : '',
                        'entry flex items-center',
                    ]"
                    v-for="(day, j) in gridData">
                    <div class="flex flex-col items-center">
                        <div
                            v-if="new Date(day.date).getDate() === 1 || j === 0"
                            class="text-xs text-gray-400 h-5">
                            {{
                                new Date(day.date).toLocaleDateString('default', {
                                    month: 'short',
                                })
                            }}
                        </div>
                        <div v-else class="h-5">&nbsp;</div>
                        <div
                            @click.stop="e => handleSetApiRequestDay(e, day)"
                            :title="`${
                                !props.errorsOnly
                                    ? day.requests.success.length
                                    : day.requests.failed.length
                            } requests - ${new Date(day.date).toDateString()}`"
                            :class="[
                                'square border-gray-300 border rounded flex justify-center items-center text-gray-500 text-xs',
                                !errorsOnly &&
                                day.requests.success.length + day.requests.failed.length > 0
                                    ? 'bg-green-100 text-gray-400'
                                    : '', // Is at least 1
                                !errorsOnly &&
                                day.requests.success.length + day.requests.failed.length >
                                    highestRequestsCount * 0.1
                                    ? 'bg-green-200 text-gray-300'
                                    : '', // Is at least 10% of total
                                !errorsOnly &&
                                day.requests.success.length + day.requests.failed.length >
                                    highestRequestsCount * 0.25
                                    ? 'bg-green-300 text-gray-200'
                                    : '', // Is at least 25% of total
                                !errorsOnly &&
                                day.requests.success.length + day.requests.failed.length >
                                    highestRequestsCount * 0.5
                                    ? 'bg-green-400 text-gray-100'
                                    : '', // Is at least 50% of total
                                !errorsOnly &&
                                day.requests.success.length + day.requests.failed.length >
                                    highestRequestsCount * 0.75
                                    ? 'bg-green-500 text-gray-50'
                                    : '', // Is at least 75% of total
                                !errorsOnly &&
                                day.requests.success.length + day.requests.failed.length >
                                    highestRequestsCount * 0.9
                                    ? 'bg-green-600 text-white'
                                    : '', // Is at least 90% of total

                                props.errorsOnly && day.requests.failed.length > 0
                                    ? 'bg-red-100 text-gray-400'
                                    : '', // Is at least 1
                                props.errorsOnly &&
                                day.requests.failed.length > highestRequestsCount * 0.1
                                    ? 'bg-red-200 text=gray-300'
                                    : '', // Is at least 10% of total
                                props.errorsOnly &&
                                day.requests.failed.length > highestRequestsCount * 0.25
                                    ? 'bg-red-300 text-gray-200'
                                    : '', // Is at least 25% of total
                                props.errorsOnly &&
                                day.requests.failed.length > highestRequestsCount * 0.5
                                    ? 'bg-red-400 text-gray-100'
                                    : '', // Is at least 50% of total
                                props.errorsOnly &&
                                day.requests.failed.length > highestRequestsCount * 0.75
                                    ? 'bg-red-500 text-gray-50'
                                    : '', // Is at least 75% of total
                                props.errorsOnly &&
                                day.requests.failed.length > highestRequestsCount * 0.9
                                    ? 'bg-red-600 text-white'
                                    : '', // Is at least 90% of total

                                day.date === homepageStore.currentSelectedDay?.date
                                    ? 'border-1 border-gray-800 font-bold'
                                    : '',
                            ]">
                            {{
                                new Date(day.date).toLocaleDateString('default', {
                                    day: 'numeric',
                                })
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.activity-grid {
    display: flex;
    gap: 2px;
    position: relative;

    .squares {
        display: flex;
        gap: 2px;
        position: relative;

        .square {
            width: 2rem;
            height: 2rem;

            &:hover {
                border-color: var(--color-border);
                cursor: pointer;
            }

            @media screen and (max-width: 1700px) {
                & {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }

            @media screen and (max-width: 1500px) {
                & {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }
}
</style>
