<script setup lang="ts"></script>

<template>
    <div class="border border-gray-300 rounded shadow bg-white flex-grow">
        <div class="flex gap-4 p-4">
            <div>
                <slot name="icon" />
            </div>
            <div>
                <div class="text-lg text-gray-400"><slot /></div>
                <div class="text-2xl font-bold">
                    <slot name="stat" />
                </div>
            </div>
        </div>

        <div v-if="$slots.link" class="bg-sky-50 p-4 border-t border-gray-200">
            <slot name="link" />
        </div>
    </div>
</template>

<style scoped></style>
