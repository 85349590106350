<template>
    <div class="flex w-full">
        <div class="w-full">
            <ApiPageHeader
                title="Retrieve an inverter"
                :endpoint="`/inverters/${inverter.id}`"
                method="GET" />
            <div class="mb-20 mt-4 pt-4 border-t border-gray-300" v-if="loadingError">
                {{ loadingError }}
            </div>
            <div v-else>
                <div class="mb-20 my-4 pt-4 border-t border-gray-300">
                    <div class="grid grid-cols-1 md:grid-cols-2 rounded-lg">
                        <div>
                            <div class="px-4 sm:px-0">
                                <h3 class="text-base font-semibold leading-7 text-gray-900">
                                    Inverter Details
                                </h3>
                                <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                    Detailed information about the Inverter
                                </p>
                            </div>
                            <div class="mt-6 border-t border-gray-100">
                                <dl class="divide-y divide-gray-100">
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Inverter Id
                                        </dt>
                                        <dd
                                            class="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.id }}
                                            <CopyToClipboardButton
                                                :toCopy="inverter.id"
                                                name="Inverter ID" />
                                        </dd>
                                    </div>
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Inverter Number
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.inverterNumber }}
                                        </dd>
                                    </div>
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Customer
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.customerName }}
                                        </dd>
                                    </div>
                                    <div
                                        v-if="inverter.consentId"
                                        class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Consent
                                        </dt>
                                        <dd
                                            class="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.consentId }}
                                            <CopyToClipboardButton
                                                :toCopy="inverter.consentId"
                                                name="Consent Id" />
                                        </dd>
                                    </div>
                                    <div
                                        v-if="inverter.hostedConsent"
                                        class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Hosted Consent
                                        </dt>
                                        <dd
                                            class="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.hostedConsent.id }}
                                            <CopyToClipboardButton
                                                :toCopy="inverter.hostedConsent.id"
                                                name="Hosted Consent Id" />
                                        </dd>
                                    </div>
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Address
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.address }}
                                        </dd>
                                    </div>
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Description
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.description }}
                                        </dd>
                                    </div>
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Data source
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ inverter.dataSource }}
                                        </dd>
                                    </div>
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Status
                                        </dt>
                                        <dt>
                                            <span
                                                class="items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                                :class="{
                                                    'bg-green-50 text-green-700 ring-green-600/20':
                                                        inverter.status === 'active',
                                                    'bg-red-50 text-red-700 ring-red-600/20':
                                                        inverter.status === 'inactive',
                                                    'bg-blue-50 text-blue-700 ring-blue-600/20':
                                                        inverter.status === 'closed',
                                                }"
                                                >{{ inverter.status }}</span
                                            >
                                        </dt>
                                    </div>
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Validity State
                                        </dt>
                                        <dt>
                                            <span
                                                v-if="inverter.validityState.missingConsent"
                                                class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                                :class="{
                                                    'bg-red-50 text-red-700 ring-red-600/20':
                                                        inverter.validityState.missingConsent,
                                                }"
                                                >Missing Consent</span
                                            >
                                            <span
                                                v-if="inverter.validityState.expiredConsent"
                                                class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                                :class="{
                                                    'bg-red-50 text-red-700 ring-red-600/20':
                                                        inverter.validityState.expiredConsent,
                                                }"
                                                >Expired Consent</span
                                            >
                                            <span
                                                v-if="inverter.validityState.isDisabledByUser"
                                                class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                                :class="{
                                                    'bg-red-50 text-red-700 ring-red-600/20':
                                                        inverter.validityState.isDisabledByUser,
                                                }"
                                                >Manually Disabled</span
                                            >
                                            <span
                                                v-if="
                                                    inverter.validityState.hasInvalidInverterNumber
                                                "
                                                class="inline-flex items-center rounded-md mx-1 my-1 px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                                :class="{
                                                    'bg-red-50 text-red-700 ring-red-600/20':
                                                        inverter.validityState
                                                            .hasInvalidInverterNumber,
                                                }"
                                                >Invalid Inverter Number</span
                                            >
                                        </dt>
                                    </div>
                                    <!-- <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                        Last Reading
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {{ formatDate(inverter.last_consumption_date) }}
                    </dd>
                </div> -->
                                    <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt class="text-sm font-medium leading-6 text-gray-900">
                                            Last Scanned
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {{ formatDate(inverter.lastDataPullDate) }}
                                        </dd>
                                    </div>
                                    <!-- <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                        Notes
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <textarea
                            v-model="new_note"
                            class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            rows="3"></textarea>
                        <div class="pt-2 flex justify-end">
                            <button
                                type="button"
                                class="inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                @click="addNote">
                                <PlusIcon
                                    class="-ml-1 mr-1.5 h-5 w-5 text-gray-400" />
                                <span>Add Note</span>
                            </button>
                        </div>

                        <div
                            class="flex pt-4"
                            v-for="note in inverter.notes"
                            :key="note.author">
                            <div class="flex-none max-width-min">
                                <PaperClipIcon
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true" />
                            </div>
                            <div class="grow">
                                <div class="text-xs font-medium pl-2">
                                    {{ note.content }}
                                </div>
                                <div class="text-xs font-xs pl-2">
                                    {{ formatDate(note.created_at) }} by
                                    {{ note.author }}
                                </div>
                            </div>
                            <div class="flex-none justify-end">
                                <TrashIcon
                                    class="-ml-1 mr-1.5 h-5 w-5 text-gray-200 hover:text-red-400"
                                    v-on:click="deleteNote(note._id)" />
                            </div>
                        </div>
                    </dd>
                </div> -->
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

                <Disclosure v-slot="{ open }">
                    <DisclosureButton
                        class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
                        <div>
                            <ApiPageHeader
                                title="Edit Inverter"
                                :endpoint="`/inverters/${inverter.id}`"
                                method="PUT" />
                        </div>
                        <ChevronDownIcon v-if="!open" class="w-5" />
                        <ChevronUpIcon v-if="open" class="w-5" />
                    </DisclosureButton>
                    <DisclosurePanel class="text-gray-500">
                        <form class="mt-4">
                            <div
                                class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
                                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                                    <div>
                                        <div class="flex">
                                            <span
                                                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                                >description</span
                                            ><span
                                                class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                                >string</span
                                            >
                                        </div>

                                        <div class="text-sm font-sm text-gray-600">
                                            The description of the inverter
                                        </div>
                                    </div>

                                    <div class="mt-2 sm:col-span-2 sm:mt-0">
                                        <div
                                            class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="text"
                                                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                                v-model="description" />
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                                    <div>
                                        <div class="flex">
                                            <span
                                                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                                >address</span
                                            ><span
                                                class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                                >string</span
                                            >
                                        </div>

                                        <div class="text-sm font-sm text-gray-600">
                                            The address of the inverter
                                        </div>
                                    </div>

                                    <div class="mt-2 sm:col-span-2 sm:mt-0">
                                        <div
                                            class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="text"
                                                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                                v-model="address" />
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                                    <div>
                                        <div class="flex">
                                            <span
                                                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                                >data_source</span
                                            ><span
                                                class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                                >string</span
                                            >
                                        </div>

                                        <div class="text-sm font-sm text-gray-600">
                                            The source of the data
                                        </div>
                                    </div>

                                    <div class="mt-2 sm:col-span-2 sm:mt-0">
                                        <div class="flex">
                                            <div>
                                                <select
                                                    v-model="dataSource"
                                                    class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                                    <option>electralink</option>
                                                    <option>DCC</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                                    <div>
                                        <div class="flex">
                                            <span
                                                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                                >update_frequency</span
                                            ><span
                                                class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                                >string</span
                                            >
                                        </div>

                                        <div class="text-sm font-sm text-gray-600">
                                            The frequency of the updates
                                        </div>
                                    </div>

                                    <div class="mt-2 sm:col-span-2 sm:mt-0">
                                        <div class="flex">
                                            <div>
                                                <select
                                                    v-model="updateFrequency"
                                                    class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                                    <option>hourly</option>
                                                    <option>daily</option>
                                                    <option>monthly</option>
                                                    <option>yearly</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                                    <div>
                                        <div class="flex">
                                            <span
                                                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                                >hosted_consent_id</span
                                            ><span
                                                class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                                >string</span
                                            >
                                        </div>

                                        <div class="text-sm font-sm text-gray-600">
                                            The hosted consent associated with this inverter.
                                        </div>
                                    </div>

                                    <div class="mt-2 sm:col-span-2 sm:mt-0">
                                        <div
                                            class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="text"
                                                class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                                v-model="hostedConsentId" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex border-0 justify-end">
                                <button
                                    type="button"
                                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    @click="updateInverter">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </DisclosurePanel>
                </Disclosure>

                <div
                    class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
                    <div>
                        <ApiPageHeader
                            title="Enable Inverter"
                            :endpoint="`/inverters/${inverter.id}/enable`"
                            method="PUT" />
                    </div>
                    <div>
                        <button
                            type="button"
                            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            @click="enableInverter">
                            Enable
                        </button>
                    </div>
                </div>

                <div
                    class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
                    <div>
                        <ApiPageHeader
                            title="Disable Inverter"
                            :endpoint="`/inverters/${inverter.id}/disable`"
                            method="PUT" />
                    </div>
                    <div>
                        <button
                            type="button"
                            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            @click="disableInverter">
                            Disable
                        </button>
                    </div>
                </div>

                <div
                    class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
                    <div>
                        <ApiPageHeader
                            title="Delete Inverter"
                            :endpoint="`/inverters/${inverter.id}`"
                            method="DELETE" />
                    </div>
                    <div>
                        <button
                            class="w-32 h-9 ml-3 text-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none disabled:opacity-50"
                            @click="openDeleteConfirmation">
                            Delete Inverter
                        </button>
                        <ConfirmAction
                            v-if="isDeleteConfirmModalOpen"
                            title="Delete Inverter"
                            message="Are you sure you want to Delete this Inverter? This action cannot be undone!"
                            confirmButtonText="Delete Inverter"
                            @confirm="deleteInverter"
                            @close="closeDeleteConfirmation" />
                    </div>
                </div>

                <div>
                    <div
                        class="mt-4 py-4 border-t border-b border-gray-200 w-full flex justify-between items-center">
                        <div>
                            <ApiPageHeader
                                title="Retrieve Interval Data"
                                :endpoint="`/interval-data`"
                                method="GET" />
                        </div>
                    </div>

                    <div class="mt-4 mb-10 w-full">
                        <div
                            class="space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5">
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                                <div>
                                    <div class="flex">
                                        <span
                                            class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                            >lookback</span
                                        ><span
                                            class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                            >string</span
                                        >
                                    </div>

                                    <div class="text-sm font-sm text-gray-600">
                                        Timeperiod to lookback, in the form of a number followed by
                                        a letter, e.g. 1d, 1w, 3m, 1y
                                    </div>
                                </div>

                                <div class="mt-2 sm:col-span-2 sm:mt-0">
                                    <div
                                        class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            type="text"
                                            class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                                            v-model="lookback" />
                                    </div>
                                </div>
                            </div>
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                                <div>
                                    <div class="flex">
                                        <span
                                            class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                                            >granularity</span
                                        ><span
                                            class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                                            >string</span
                                        >
                                    </div>

                                    <div class="text-sm font-sm text-gray-600">
                                        How granular to return the data. Half-hourly is denoted by
                                        'hh'
                                    </div>
                                </div>

                                <div class="mt-2 sm:col-span-2 sm:mt-0">
                                    <div class="flex">
                                        <div>
                                            <select
                                                v-model="granularity"
                                                class="mt-1 px-10 pl-5 py-2 text-base text-gray-900 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                                <option>hh</option>
                                                <option>day</option>
                                                <option>week</option>
                                                <option>month</option>
                                                <option>year</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex border-0 justify-end">
                            <button
                                @click="onSubmit"
                                type="button"
                                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Submit
                            </button>
                        </div>
                    </div>

                    <div class="w-full 2xl:w-2/3">
                        <apexchart :options="chartOptions" :series="chartData.series"></apexchart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Inverter } from '@/types/inverter';

import { ref, onMounted, watch } from 'vue';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
import moment from 'moment';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import ConfirmAction from '@/components/ConfirmAction/index.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton/index.vue';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const emptyInverter = {
    id: '',
    inverterNumber: '',
    dataSource: '',
    customerName: '',
    address: '',
    description: '',
    status: '',
    consentId: '',
    hostedConsent: {},
    lastDataPullDate: '',
    lastDataPullStatus: '',
    validityState: {
        missingConsent: false,
        expiredConsent: false,
        isDisabledByUser: false,
        hasInvalidInverterNumber: false,
    },
};

const router = useRouter();
const route = useRoute();
const apiClient = new ApiClient();
const userStore = useUserStore();

const inverter = ref<Inverter>(emptyInverter);
const granularity = ref<string>('hh');
const lookback = ref<string>('2w');
const startDate = ref<string>('');
const endDate = ref<string>('');
const isDeleteConfirmModalOpen = ref<boolean>(false);

const toast = useToast();
const address = ref<string>('');
const description = ref<string>('');
const dataSource = ref<string>('');
const updateFrequency = ref<string>('');
const consentId = ref<string | null>('');
const hostedConsentId = ref<string>('');
const loadingError = ref<string | null>(null);

const chartOptions = ref({
    theme: {
        palette: 'palette2',
    },
    chart: {
        id: 'chart',
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
        },
        toolbar: {
            autoSelected: 'zoom',
        },
        type: 'area',
    },
    stroke: {
        show: true,
        curve: ['smooth', 'smooth', 'smooth', 'smooth'],
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 0,
            opacityFrom: 0,
            opacityTo: 0,
        },
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        type: 'datetime',
        labels: {
            datetimeUTC: true,
        },
    },
    yaxis: [
        {
            seriesName: 'electricity',
            show: true,
            title: {
                text: 'kwh',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                formatter: function (value: any) {
                    return value;
                },
            },
        },
    ],
    tooltip: {
        enabled: true,
        x: {
            show: true,
            format: 'dd MMM yyyy - H:mm',
        },
        y: {
            formatter: function (value: any) {
                return `${value} kWh`;
            },
        },
    },
});

const chartData = ref({
    series: [
        {
            name: 'system_consumption',
            data: [] as any,
        },
        {
            name: 'pv_generation',
            data: [],
        },
        {
            name: 'battery_charge',
            data: [],
        },
        {
            name: 'battery_discharge',
            data: [],
        },
    ],
});

function openDeleteConfirmation() {
    isDeleteConfirmModalOpen.value = true;
}

function closeDeleteConfirmation() {
    isDeleteConfirmModalOpen.value = false;
}

async function deleteInverter() {
    await apiClient
        .deleteInverter({
            id: route.params.id as string,
            apiKey: userStore.currentApiKey,
        })
        .then(() => {
            toast.success('Inverter deleted');
            router.push('/inverters');
        })
        .catch(e => {
            toast.error(e.errorMessage);
        });
}

async function updateInverter() {
    apiClient
        .updateInverter({
            id: route.params.id as string,
            apiKey: userStore.currentApiKey,
            address: address.value,
            description: description.value,
            data_source: dataSource.value,
            update_frequency: updateFrequency.value,
            hosted_consent_id: hostedConsentId.value,
        })
        .then(async () => {
            toast.success('Inverter updated');
            inverter.value = await getInverterDetails();
            description.value = '';
            dataSource.value = '';
            address.value = '';
            updateFrequency.value = '';
        })
        .catch(e => {
            toast.error(e.errorMessage);
        });
}

function formatDate(value: string) {
    if (value) {
        return moment(String(value)).format('DD/MM/YY');
    }
}

async function getInverterDetails() {
    return await apiClient
        .getInverter({
            inverterId: route.params.id as string,
            apiKey: userStore.currentApiKey,
        })
        .catch(e => {
            loadingError.value = e.errorMessage;
            return emptyInverter;
        });
}

async function getIntervalData() {
    return await apiClient
        .getIntervalData(
            {
                inverterId: route.params.id as string,
                lookback: lookback.value,
                granularity: granularity.value,
                apiKey: userStore.currentApiKey,
                startDate: startDate.value,
                endDate: endDate.value,
            },
            false,
        )
        .catch(() => {
            return {
                consumption: [],
                intensity: [],
            };
        });
}

async function enableInverter() {
    await apiClient
        .enableInverter({
            id: route.params.id as string,
            apiKey: userStore.currentApiKey,
        })
        .then(async () => {
            inverter.value = await getInverterDetails();
            toast.success('Inverter enabled');
        })
        .catch(e => {
            toast.error(e.errorMessage);
        });
}

async function disableInverter() {
    await apiClient
        .disableInverter({
            id: route.params.id as string,
            apiKey: userStore.currentApiKey,
        })
        .then(async () => {
            inverter.value = await getInverterDetails();
            toast.success('Inverter disabled');
        })
        .catch(e => {
            toast.error(e.errorMessage);
        });
}

async function onSubmit() {
    let intervalData = await getIntervalData();

    chartData.value.series[0].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y0,
        };
    });
    chartData.value.series[1].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y1,
        };
    });
    chartData.value.series[2].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y2,
        };
    });
    chartData.value.series[3].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y3,
        };
    });
}

onMounted(async () => {
    inverter.value = await getInverterDetails();
    let intervalData = await getIntervalData();
    chartData.value.series[0].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y0,
        };
    });
    chartData.value.series[1].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y1,
        };
    });
    chartData.value.series[2].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y2,
        };
    });
    chartData.value.series[3].data = intervalData.consumption.map((interval: any) => {
        return {
            x: interval.x,
            y: interval.y3,
        };
    });
});

watch(
    () => userStore.currentApiKey,
    async () => {
        router.replace('/inverters');
    },
);
</script>
