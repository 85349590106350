<script setup lang="ts">
import { ref } from 'vue';
import { ApiClient } from '@/helpers/apiClient';
import { useUserStore } from '@/stores/user';
import Spinner from '@/components/Spinner/Spinner.vue';
import { MeterDataSource } from '@/types/meter-data-source/MeterDataSource.types';
const apiClient = new ApiClient();
const userStore = useUserStore();

const meterNumber = ref<string>('');
const canFetch = ref<boolean | null>(null);
const loading = ref<boolean>(false);

async function onLookup() {
    if (meterNumber.value.length > 0) {
        try {
            loading.value = true;
            canFetch.value = null;
            const response = await apiClient.meterNumberLookup(
                userStore.currentApiKey,
                meterNumber.value,
                MeterDataSource.electralink,
            );
            canFetch.value = response.data.data.profile_class === '0';
            loading.value = false;
        } catch (e: any) {
            loading.value = false;
            canFetch.value = false;
        }
    }
}
</script>

<template>
    <div class="text-gray-500 mb-2 text-sm">
        Enter the MPAN you want to lookup below to see if data is available through Electralink.
    </div>
    <form @submit.prevent="onLookup" class="flex gap-4">
        <input
            placeholder="Enter a meter number and search"
            type="text"
            class="w-full block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
            v-model="meterNumber" />
        <button
            type="button"
            class="w-64 text-center inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            :disabled="loading"
            @click="onLookup">
            <div v-if="!loading">Lookup Meter Number</div>
            <Spinner v-else />
        </button>
    </form>
    <div
        v-if="!loading && canFetch !== null"
        class="mt-4 p-4 bg-gray-100 border-gray-300 border"
        :class="[canFetch ? 'bg-green-100' : 'bg-red-100']">
        <div>
            <span v-if="canFetch"
                >Good news, we should be able to get that meter data for you using Electralink</span
            >
            <span v-else
                >Unfortunately that meter data isn't available to collect through Electralink</span
            >
        </div>
    </div>
</template>

<style scoped></style>
