<script setup lang="ts">
import type { ApiRequest } from '@/types/apiRequests';

const props = defineProps<{
    method: string;
}>();
</script>

<template>
    <button
        class="bg-green-200 rounded-full text-xs font-xs px-3 py-0.5"
        :class="[
            props.method.toUpperCase() === 'DELETE' ? 'bg-red-200' : '',
            props.method.toUpperCase() === 'GET' ? 'bg-blue-200' : '',
        ]">
        {{ props.method.toUpperCase() }}
    </button>
</template>

<style scoped></style>
