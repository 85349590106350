<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import Loading from '@/components/Loading/Loading.vue';
import { ApiClient } from '@/helpers/apiClient';
import { useHomepageStore } from '@/stores/homepageStore';
import StatCard from '@/components/StatCard/StatCard.vue';
import type { MeterModel } from '@/types/meter/meter.types';
import type { CustomerModel } from '@/types/customer/customer.types';
import Heading from '@/components/Heading/Heading.vue';
import { ArrowTopRightOnSquareIcon, BoltIcon } from '@heroicons/vue/24/outline';
import type { Meter } from '@/types/meter';

const apiClient = new ApiClient();
const homepageStore = useHomepageStore();
const loading = ref(false);

const chart = computed(() => {
    const meters = homepageStore.allMeters;
    const validEnabledMeters = meters.filter(m => m.valid && m.enabled).length;
    const validDisabledMeters = meters.filter(m => m.valid && !m.enabled).length;
    const invalidErrorsMeters = meters.filter(m => !m.valid && m.errorCode !== null).length;

    return {
        series: [validEnabledMeters, validDisabledMeters, invalidErrorsMeters],
        options: {
            chart: {
                type: 'donut',
            },
            labels: ['Active', 'Disabled', 'Errored'],
            colors: ['#22c55e', '#eab308', '#ef4444'],
        },
    };
});

const newMeters = computed(() => {
    const last7 = new Date();
    last7.setDate(last7.getDate() - 7);

    const meters = homepageStore.allMeters;
    return meters.filter(m => {
        return new Date(m.created_at).getTime() >= last7.getTime();
    }).length;
});

const customers = computed(() => {
    const meters = homepageStore.allMeters;
    const customers: Array<{ customer: CustomerModel; meters: Meter[] }> = [];
    meters.forEach(m => {
        const foundIndex = customers.findIndex(c => c.customer.id === m.customer.id);
        if (foundIndex === -1) {
            customers.push({ customer: m.customer, meters: [m] });
        } else {
            customers[foundIndex].meters.push(m);
        }
    });
    customers.sort((a, b) => {
        if (a.meters.length > b.meters.length) {
            return -1;
        } else {
            return 1;
        }
        return 0;
    });
    return customers;
});

watch(
    () => homepageStore.allMeters,
    () => {
        loading.value = false;
    },
);
</script>

<template>
    <div v-if="loading" class="h-full w-full flex items-center justify-center"><Loading /></div>
    <div
        v-if="!loading && homepageStore.allMeters.length > 0"
        class="h-full grid grid-cols-3 gap-4 items-start">
        <div class="flex flex-col gap-4">
            <StatCard>
                <template v-slot:icon
                    ><BoltIcon class="w-12 text-sky-600 bg-sky-100 p-2 rounded"
                /></template>
                <template v-slot:stat>{{ newMeters }}</template>
                <template v-slot:default>New meters (last 7 days)</template>
            </StatCard>
            <div
                class="flex flex-col border border-gray-300 rounded shadow bg-white max-h-96 overflow-y-auto">
                <RouterLink
                    v-for="customer in customers"
                    :to="{
                        path: `/meters`,
                        query: { customer_id: customer.customer.id },
                    }">
                    <div
                        class="grid grid-cols-4 p-4 gap-4 items-center hover:cursor-pointer hover:bg-slate-100">
                        <div class="col-span-3">
                            <div>{{ customer.customer.name }}</div>
                            <div class="text-xs text-gray-500">{{ customer.customer.address }}</div>
                        </div>
                        <div class="text-gray-500 text-right">
                            <div>
                                {{ customer.meters.filter(m => m.enabled && m.valid).length }}
                                active
                            </div>
                            <div>
                                {{ customer.meters.filter(m => !m.enabled || !m.valid).length }}
                                inactive
                            </div>
                        </div>
                    </div>
                </RouterLink>
            </div>
        </div>
        <div class="flex flex-col justify-between">
            <apexchart
                class="m-4"
                :options="chart.options"
                :series="chart.series"
                height="300px"></apexchart>
        </div>
    </div>
</template>

<style scoped></style>
