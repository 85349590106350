<template>
    <Heading class="mb-4">Team Management</Heading>
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Email
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(user, userIdx) in users"
                                :key="user.email"
                                :class="userIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                                <td
                                    class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex">
                                    {{ user.email }}
                                    <span v-if="userStore.user.id === user.id">&nbsp;(you)</span>
                                </td>
                                <td class="">
                                    <TrashIcon
                                        v-if="userStore.user.id !== user.id"
                                        class="w-4"
                                        @click="
                                            () => {
                                                isConfirmDeleteOpen = true;
                                                userToDelete = user;
                                            }
                                        " />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <Disclosure v-slot="{ open }">
        <DisclosureButton
            class="mt-4 py-4 border-t border-b border-gray-200 w-full flex bg-gray-50 gap-4 px-4 items-center">
            <h2 class="text-sm text-gray-900">Add a teammate</h2>
            <ChevronDownIcon v-if="!open" class="w-5" />
            <ChevronUpIcon v-if="open" class="w-5" />
        </DisclosureButton>
        <DisclosurePanel>
            <div class="bg-white flex gap-4 p-4 border border-gray-300 border-t-0">
                <input
                    v-model="newUserEmail"
                    type="email"
                    class="flex-grow border border-gray-300 rounded text-sm"
                    placeholder="Enter an email" />
                <Button text="Add" :disabled="newUserEmail.length === 0" @click="createUser" />
            </div>
        </DisclosurePanel>
    </Disclosure>
    <ConfirmAction
        v-if="isConfirmDeleteOpen"
        title="Delete user"
        message="Are you sure you wish to remove this user from your account?"
        confirmButtonText="Delete user"
        @confirm="removeUserFromAccount"
        @close="closeDeleteConfirmation" />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import type { Account, User } from '@/types/user'
import { useUserStore } from '@/stores/user'
import { ApiClient } from '@/helpers/apiClient'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid'
import { TrashIcon } from '@heroicons/vue/24/outline'
import Heading from '@/components/Heading/Heading.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import Button from '@/components/Button/Button.vue'
import { useToast } from 'vue-toastification'
import ConfirmAction from '@/components/ConfirmAction/index.vue'
const toast = useToast()

const users = ref<User[]>([])
const userStore = useUserStore()
const apiClient = new ApiClient()

const newUserOpen = ref(false)
const newUserEmail = ref('')
const userToDelete = ref<User | null>(null)

const isConfirmDeleteOpen = ref(false)
async function getUsersForAccount () {
    users.value = await apiClient.getAccountUsers(userStore.currentAccount.id, userStore.user.accessToken).catch(e => {
        toast.error(e.errorMessage);
        return [];
    })
}

function closeDeleteConfirmation() {
    isConfirmDeleteOpen.value = false;
}

async function removeUserFromAccount() {
    const userToBeRemoved = await apiClient.getUserById(userToDelete.value?.id as string, userStore.user.accessToken).catch(e => {
        toast.error(e.errorMessage);
        return null;
    })
    if (userToBeRemoved) {
        const restOfAccounts = userToBeRemoved.accounts
            .filter((account: Account) => {
                return account.id !== userStore.currentAccount.id;
            })
            .map((account: Account) => {
                return account.id;
            });
        const result = await apiClient.removeUserFromAccount({
            id: userToBeRemoved.id as string,
            accounts: restOfAccounts,
        }, userStore.user.accessToken).catch(e => {
            return {success: false}
        })
        if (result.success) {
            toast.success('User removed from account!');
            await getUsersForAccount();
        }
    }
}

async function createUser() {
    const result = await apiClient.createNewUser({
        email: newUserEmail.value,
        accountId: userStore.currentAccount.id,
    }, userStore.user.accessToken).catch(() => {
        return {success: false};
    })
    if (result.success) {
        toast.success('User created!');
        newUserOpen.value = false;
        newUserEmail.value = '';
        await getUsersForAccount();
    }
}

onMounted(async () => {
    getUsersForAccount()
});

watch(
    () => userStore.currentApiKey,
    async () => {
        await getUsersForAccount();
    },
);
</script>
