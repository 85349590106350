<script setup lang="ts">
import ConsentMethod from '@/components/ConsentMethod/ConsentMethod.vue';
import { useRouter } from 'vue-router';
import ConsentStatus from '@/components/ConsentStatus/ConsentStatus.vue';
import Button from '@/components/Button/Button.vue';
import ConsentFlowType from '@/components/ConsentFlowType/ConsentFlowType.vue';
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';

const props = defineProps<{
    hostedConsents: HostedConsentModel[];
}>();

const router = useRouter();
</script>

<template>
    <table class="divide-y w-full divide-gray-300">
        <thead>
            <tr>
                <th
                    scope="col"
                    class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Customer
                </th>
                <th
                    scope="col"
                    class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                </th>
                <th
                    scope="col"
                    class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Consent Method
                </th>
                <th
                    scope="col"
                    class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Flow Type
                </th>
                <th
                    scope="col"
                    class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Expiry
                </th>
                <th
                    scope="col"
                    class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created At
                </th>
                <th
                    scope="col"
                    class="px-3 pl-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Consented At
                </th>
                <th
                    scope="col"
                    class="py-3.5 pl-0 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Status
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-if="hostedConsents.length === 0">
                <td colspan="8" class="text-center py-8 text-gray-500">
                    There are no consents to show here.
                </td>
            </tr>
            <tr v-for="hostedConsent in props.hostedConsents" :key="hostedConsent.id">
                <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div
                        v-if="hostedConsent.customer"
                        class="font-medium text-gray-900 hover:cursor-pointer hover:underline"
                        @click="router.push(`/customers/${hostedConsent.customer.id}`)">
                        {{ hostedConsent.customer.name }}
                    </div>
                    <span v-else>
                        <i class="text-gray-400">Customer deleted</i>
                    </span>
                </td>
                <td class="py-5 text-sm text-gray-500 w-52">
                    {{ hostedConsent.description }}
                </td>
                <td class="whitespace-nowrap py-5 text-sm text-gray-500">
                    <ConsentMethod
                        v-if="hostedConsent.consent_method"
                        :method="hostedConsent.consent_method" />
                </td>
                <td class="whitespace-nowrap py-5 text-sm text-gray-500">
                    <ConsentFlowType
                        v-if="hostedConsent.flow_type"
                        :flowType="hostedConsent.flow_type" />
                </td>
                <td class="whitespace-nowrap py-5 text-sm text-gray-500">
                    {{ new Date(hostedConsent.expiry).toDateString() }}
                </td>
                <td class="whitespace-nowrap py-5 text-sm text-gray-500">
                    {{ new Date(hostedConsent.created_at).toDateString() }}
                </td>

                <td class="whitespace-nowrap py-5 text-sm text-gray-500">
                    {{
                        hostedConsent.consented_at
                            ? new Date(hostedConsent.consented_at).toDateString()
                            : ''
                    }}
                </td>
                <td class="whitespace-nowrap py-5 text-sm text-gray-500">
                    <ConsentStatus :hosted-consent="hostedConsent" />
                </td>
                <td>
                    <Button
                        text="View"
                        @click="router.push(`/hosted-consents/${hostedConsent.id}`)" />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<style scoped></style>
