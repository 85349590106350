<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import { useHostedConsentStore } from '@/stores/hostedConsentStore';
import ApiPageHeader from '@/components/ApiPageHeader/ApiPageHeader.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton/index.vue';
import ConsentMethod from '@/components/ConsentMethod/ConsentMethod.vue';
import ConsentType from '@/components/ConsentFlowType/ConsentFlowType.vue';
import { downloadBase64File } from '@/helpers/downloadBase64File';
import { useUserStore } from '@/stores/user';
import Button from '@/components/Button/Button.vue';
import ConfirmAction from '@/components/ConfirmAction/index.vue';
import { ApiClient } from '@/helpers/apiClient';
import { useToast } from 'vue-toastification';
import type { MeterModel } from '@/types/meter/meter.types';
import type { HostedConsentModel } from '@/types/hosted-consents/hosted-consent.types';
import { bytesToReadableSize } from '../../utils/bytesToReadableSize';

const router = useRouter();
const route = useRoute();
const toast = useToast();
const hostedConsentStore = useHostedConsentStore();
const userStore = useUserStore();
const apiClient = new ApiClient();

const consent = ref<HostedConsentModel | null>(null);
const evidence = ref<string | null>(null);

const isConfirmModalOpen = ref<boolean>(false);
const metersToDelete = ref<MeterModel[]>([]);

function handleDownloadFile(type?: string, data?: string, name?: string) {
    if (type && data && name) {
        downloadBase64File(type, data, name);
    }
}

async function deleteHostedConsentCheck() {
    if (consent.value) {
        await apiClient
            .getHostedConsentMeters(userStore.currentApiKey, consent.value.id)
            .then(async res => {
                const meters = res.data.data;
                if (meters.length > 0) {
                    metersToDelete.value = meters;
                    openDeleteHostedConsent();
                } else {
                    await deleteHostedConsent();
                }
            })
            .catch();
    }
}

async function deleteHostedConsent() {
    if (consent.value) {
        await apiClient
            .deleteHostedConsent(userStore.currentApiKey, consent.value.id)
            .then(() => {
                toast.success('Hosted consent successfully deleted');
                router.push(`/hosted-consents`);
            })
            .catch(e => {
                toast.error(e.errorMessage);
            });
    }
}

function openDeleteHostedConsent() {
    isConfirmModalOpen.value = true;
}

function closeDeleteHostedConsent() {
    isConfirmModalOpen.value = false;
}

onMounted(async () => {
    await hostedConsentStore.getHostedConsent(route.params.id as string).catch();
});

watch(
    () => userStore.currentApiKey,
    async () => {
        await hostedConsentStore.getHostedConsent(route.params.id as string).catch();
    },
);

watch(
    () => hostedConsentStore.hostedConsent,
    () => {
        if (hostedConsentStore.hostedConsent !== null) {
            consent.value = hostedConsentStore.hostedConsent;
        } else {
            consent.value = null;
        }
    },
);

watch(
    () => hostedConsentStore.hostedConsentEvidence,
    () => {
        if (hostedConsentStore.hostedConsentEvidence !== null) {
            evidence.value = hostedConsentStore.hostedConsentEvidence;
        } else {
            evidence.value = null;
        }
    },
);
</script>

<template>
    <div :key="consent.id" v-if="consent !== null">
        <ApiPageHeader
            :title="`Hosted Consent for ${consent?.customer?.name} (expires ${new Date(
                consent.expiry,
            ).toDateString()})`"
            :endpoint="`/hosted-consents/${consent.id}`"
            method="get" />
        <div v-if="consent" class="divide-y divide-gray-100 border-t border-b border-gray-100">
            <div v-if="consent.description" class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Description</span>
                    <span class="text-gray-400">Description of the hosted consent</span>
                </div>
                <span>{{ consent.description }}</span>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Hosted Consent Id</span>
                    <span class="text-gray-400">The id of this hosted consent</span>
                </div>
                <span>{{ consent.id }}</span>
                <div>
                    <CopyToClipboardButton :toCopy="consent.id" name="Hosted Consent ID" />
                </div>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Consent Flow Link</span>
                    <span class="text-gray-400">The link to complete the hosted consent flow</span>
                </div>
                <div class="text-ellipsis overflow-hidden">
                    <a
                        class="whitespace-nowrap anchor"
                        target="_blank"
                        :href="consent.hosted_consent_link"
                        >{{ consent.hosted_consent_link }}</a
                    >
                </div>
                <div>
                    <CopyToClipboardButton
                        :toCopy="consent.hosted_consent_link"
                        name="Hosted Consent Link" />
                </div>
            </div>
            <div v-if="consent.consent_method" class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Consent Method</span>
                    <span class="text-gray-400">The method used to complete this consent</span>
                </div>
                <div>
                    <ConsentMethod :method="consent.consent_method" />
                </div>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Flow Type</span>
                    <span class="text-gray-400">The flow used for this consent.</span>
                </div>
                <div>
                    {{ consent.flow_type }}
                </div>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Customer</span>
                    <span class="text-gray-400">The customer attached to this consent</span>
                </div>
                <div>
                    <span
                        class="font-bold hover:cursor-pointer"
                        @click="router.push(`/customers/${consent?.customer.id}`)"
                        >{{ consent?.customer?.name }}
                    </span>
                </div>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Expiry</span>
                    <span class="text-gray-400">When this consent expires</span>
                </div>
                <div>
                    <span
                        v-if="new Date() > new Date(consent.expiry)"
                        class="text-red-500 font-bold"
                        >{{ new Date(consent.expiry).toDateString() }}</span
                    >
                    <span v-else>{{ new Date(consent.expiry).toDateString() }}</span>
                </div>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Created At</span>
                    <span class="text-gray-400">When this consent was created</span>
                </div>
                <div>
                    {{ new Date(consent.created_at).toDateString() }}
                </div>
            </div>
            <div v-if="consent.consented_at" class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Consented At</span>
                    <span class="text-gray-400">When this consent was valid from</span>
                </div>
                <div>
                    {{ new Date(consent.consented_at).toDateString() }}
                </div>
            </div>
            <div v-if="consent.revoked_at" class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Revoked At</span>
                    <span class="text-gray-400">When this consent was revoked</span>
                </div>
                <div>
                    <span class="text-red-500 font-bold">{{
                        new Date(consent.revoked_at).toDateString()
                    }}</span>
                </div>
            </div>
            <div class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Consented Meter Numbers</span>
                    <span class="text-gray-400"
                        >The meter numbers (if any) that were consent to.</span
                    >
                </div>
                <div
                    v-if="consent.meter_numbers?.length === 0"
                    class="text-gray-500 italic font-bold">
                    all meters
                </div>
                <ul v-else class="flex flex-col gap-2">
                    <li
                        v-for="meter_number in consent.meter_numbers"
                        class="font-bold flex items-center">
                        {{ meter_number }}
                        <CopyToClipboardButton name="Meter number" :to-copy="meter_number" />
                    </li>
                </ul>
            </div>
            <div v-if="consent.consented_at" class="py-2 grid grid-cols-3 items-center gap-2">
                <div class="flex flex-col">
                    <span>Consenting Party Information</span>
                    <span class="text-gray-400"
                        >Information about the consenting party (if provided)</span
                    >
                </div>
                <div>
                    <ul>
                        <li v-if="consent.consentee_full_name">
                            <strong>Name:</strong> {{ consent.consentee_full_name }}
                        </li>
                        <li v-if="consent.consentee_job_title">
                            <strong>Job Title:</strong> {{ consent.consentee_job_title }}
                        </li>
                        <li v-if="consent.consentee_email">
                            <strong>Email:</strong> {{ consent.consentee_email }}
                        </li>
                        <li v-if="consent.evidence_document_metadata">
                            <strong>Evidence: </strong>
                            <span
                                v-if="consent.evidence_document_metadata"
                                class="text-blue-600 hover:cursor-pointer hover:text-blue-800 hover:underline"
                                @click="
                                    () => {
                                        if (
                                            consent &&
                                            evidence &&
                                            consent.evidence_document_metadata
                                        ) {
                                            handleDownloadFile(
                                                consent.evidence_document_metadata.file_type,
                                                evidence,
                                                consent.evidence_document_metadata.name,
                                            );
                                        }
                                    }
                                ">
                                {{ consent.evidence_document_metadata?.name }}
                                <span
                                    >({{
                                        bytesToReadableSize(
                                            consent.evidence_document_metadata?.size,
                                        )
                                    }})</span
                                >
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mt-4 flex justify-end">
            <Button text="Delete" delete @click="deleteHostedConsentCheck" />
            <ConfirmAction
                v-if="isConfirmModalOpen"
                title="Delete hosted consent"
                message="Warning! If you delete this hosted consent, it will also be removed from the following meters:"
                confirmButtonText="Delete hosted consent"
                @confirm="deleteHostedConsent"
                @close="closeDeleteHostedConsent">
                <div v-if="metersToDelete.length > 0">
                    <div class="mt-4">
                        <strong>{{ metersToDelete.length }} meter(s) affected</strong>
                    </div>
                    <ul
                        v-for="meter in metersToDelete"
                        class="mt-4 flex gap-2 flex-col metersToDelete">
                        <li class="p-2 border-gray-200 border">
                            <div class="font-bold">
                                <RouterLink
                                    target="_blank"
                                    class="font-bold hover:underline"
                                    :to="{
                                        path: `/meters/${meter.id}`,
                                    }"
                                    >{{ meter.meter_number }}</RouterLink
                                >
                            </div>
                            <div class="text-gray-500">{{ meter.address }}</div>
                        </li>
                    </ul>
                </div>
            </ConfirmAction>
        </div>
    </div>
</template>

<style scoped>
.metersToDelete {
    max-height: 300px;
    overflow-y: auto;
}

.anchor {
    text-decoration: underline;
    color: blue;
}

.anchor:hover {
    text-decoration: none;
    cursor: pointer;
}
</style>
