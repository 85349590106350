<script setup lang="ts">
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import type { ImageMetadata } from '@/types/common.types';
import base64ToDataURL from '@/helpers/base64ToDataURL';

const imageFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];

const toast = useToast();

const emit = defineEmits(['change']);

const props = defineProps<{
    image: string | null;
    metadata: ImageMetadata | null;
}>();

const file = ref<null | File>(null);
const preview = ref<null | string>(props.image);
const metadata = ref<ImageMetadata | null>(props.metadata != null ? props.metadata : null);

function filePicked(e: Event) {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files instanceof FileList && files.length > 0) {
        const selectedFile: File = files[0] as File;
        if (isAllowed(selectedFile)) {
            file.value = selectedFile;
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = function () {
                const result = reader.result as null | string;
                if (result != null) {
                    const b64 = result.replace(/^data:?[A-z]*\/?[A-z]*;base64,/, '');
                    preview.value = b64;

                    const imageMetadata: ImageMetadata = {
                        name: selectedFile.name,
                        file_type: selectedFile.type,
                        size: selectedFile.size,
                    };

                    metadata.value = imageMetadata;

                    emit('change', { image: preview.value, metadata: imageMetadata });
                }
            };
            reader.onerror = function (error) {
                toast.error("We weren't able to create a preview of that image");
            };
        } else {
            toast.error('Images can only be a jpeg, jpg, png or svg file.');
        }
        if (selectedFile.size > 2000000) {
            // That's 2MB to a non-mentat
            toast.error('Your logo cannot be larger than 2MB');
        }
    } else {
        file.value = null;
        metadata.value = null;
        emit('change', { image: null, metadata: null });
    }
}

function isAllowed(file: File): boolean {
    return imageFileTypes.includes(file.type);
}
</script>

<template>
    <div class="image-upload bg-gray-200">
        <div class="choose-image text-xs text-gray-500" v-if="!image">Click to upload an image</div>
        <div class="preview" v-if="image">
            <img class="preview" v-bind:src="image" crossorigin="anonymous" />
        </div>
        <input type="file" class="file-input" @change="filePicked" />
    </div>
</template>

<style scoped>
.image-upload {
    padding: 0.25rem;
    border: 1px dashed var(--vt-c-divider-dark-2);
    border-radius: 0.25rem;
    width: 200px;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.file-input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
}

.preview {
    width: 100%;
}
</style>
